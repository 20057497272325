<div class="w-full flex flex-col">
  <div *ngIf="safeAccountVerification" class="flex flex-col gap-3">
    <span class="text-18 sm:text-24 leading-122 font-700 text-gray-700">
      Potvrdili ste da želite da sačuvate vaše podatke
    </span>
    <span class="text-16 leading-137 text-gray-400">
      Kako biste sačuvali vaše podatke za buduća plaćanja, zbog sigurnosti je potrebno da potvrdite email adresu.
    </span>
  </div>
  <div *ngIf="!safeAccountVerification" class="flex flex-col gap-3">
    <span class="text-18 sm:text-24 leading-122 font-700 text-gray-700">
      Popuni iz sačuvanog
    </span>
    <span class="text-16 leading-137 text-gray-400">
      Kako biste ispunili vaše podatke, zbog sigurnosti je potrebno da potvrdite email adresu.
    </span>
  </div>
  <div class="mt-4 sm:mt-7">
    <form [formGroup]="emailForm" (ngSubmit)="onSubmit()">
      <label for="email" class="input-label">
        Email adresa
      </label>
      <input id="email"
             formControlName="email"
             type="email"
             placeholder="Vaš email"
             class="input-field"/>
      <span *ngIf="!email.valid && email.touched && !email.value"
            class="input-error-message">
        Obavezno polje
      </span>
      <span *ngIf="(!email.valid && email.touched && email.value) || emailError"
            class="input-error-message">
        Pogrešan format email adrese
      </span>
      <button type="submit"
              [disabled]="!emailForm.valid || loading"
              class="mt-5 sm:mt-8 w-full btn-primary">
        Dalje
      </button>
    </form>
  </div>
</div>
