<div class="w-full mb-6 flex flex-col gap-6">
  <div class="flex items-center gap-2">
    <input type="radio" id="card" name="cardSelector" [checked]="true" (change)="handleSavedCard()">
    <label for="card" class="block flex-1 bg-white border border-gray-300 p-6 rounded-2 hover:border-gray-400">
      <div class="flex items-center gap-3">
        <div *ngIf="card.card_brand === 'MASTERCARD'" class="flex items-center">
          <img src="assets/img/logos/mastercard.png" width="110" height="64" class="w-12 sm:w-18 h-auto" alt="mastercard"/>
        </div>
        <div *ngIf="card.card_brand === 'VISA'" class="flex items-center">
          <img src="assets/img/logos/visa.png" width="111" height="64" class="w-12 sm:w-18 h-auto" alt="mastercard"/>
        </div>
        <span class="text-18 leading-122 text-gray-900 font-700">
          **** {{ card.last_four_digits }}
        </span>
      </div>
      <div class="w-full mt-4 flex flex-col md:flex-row gap-y-1 text-14 leading-122 text-gray-900 font-500">
        <span>
          {{ card.card_holder }}
        </span>
        <span class="md:flex-1 md:text-right">
          {{ card.expiry_month }} / {{ card.expiry_year }}
        </span>
      </div>
    </label>
    <button type='button'
            (click)="openDetachCardModal()"
            class="w-8 h-8 border border-status-red-400 rounded-full flex items-center justify-center">
      <fa-icon [icon]="faTrashCan" class="w-16 text-status-red-400"></fa-icon>
    </button>
  </div>
  <div class="flex items-center gap-2">
    <input type="radio" id="other" name="cardSelector" (change)="handleOther()">
    <label for="other" class="text-14 leading-140 text-brand-400 font-700">
      Druga kartica
    </label>
  </div>
</div>

<div *ngIf="detachCardModalVisible">
  <app-detach-card-modal
      [card]="card"
      [verification_uuid]="verification_uuid"
      (canceled)="detachCardModalCanceled()"
      (cardDetached)="onCardDetached()">
  </app-detach-card-modal>
</div>
