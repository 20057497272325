import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormControlValidationDirective } from '../Library/form/directives/form-control-validation.directive';
import { FormSubmitValidationDirective } from '../Library/form/directives/form-submit-validation.directive';
import { LoaderComponent } from './components/loader/loader.component';
import { FlRouterLinkDirective } from '../Library/directives/fl-router-link.directive';
import { FlRouterLinkActiveDirective } from '../Library/directives/fl-router-link-active.directive';
import { FlHighlightDirective } from '../Library/directives/fl-highlight.directive';
import { CheckScriptsPipe, SafeHtmlPipe } from './pipes/safe-html';
import { TruncatePipe } from './pipes/truncate.pipe';
import { FlLinkDirective } from '../Library/directives/fl-link.directive';
import { ImgFallbackDirective } from '../Library/directives/img-falback.directive';
import { HttpClientJsonpModule } from '@angular/common/http';
import { FlHighlightPipe } from './pipes/fl-highligh';

@NgModule({
    declarations: [
        LoaderComponent,
        FormControlValidationDirective,
        FormSubmitValidationDirective,
        FlRouterLinkDirective,
        FlRouterLinkActiveDirective,
        FlLinkDirective,
        FlHighlightDirective,
        FlHighlightPipe,
        ImgFallbackDirective,
        SafeHtmlPipe,
        TruncatePipe,
        CheckScriptsPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        RouterModule,
        HttpClientJsonpModule
    ],
    exports: [
        LoaderComponent,
        FormControlValidationDirective,
        FormSubmitValidationDirective,
        FlRouterLinkDirective,
        FlRouterLinkActiveDirective,
        FlLinkDirective,
        FlHighlightDirective,
        SafeHtmlPipe,
        FlHighlightPipe,
        TruncatePipe,
        CheckScriptsPipe,
        ImgFallbackDirective
    ],
    providers: []
})
export class SharedModule {
}
