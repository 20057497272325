import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Verification } from '../../../../../verification/models/verification.model';
import { CountdownService } from '../../services/countdown.service';

@Component({
  selector: 'app-account-modal',
  templateUrl: './account-modal.component.html'
})
export class AccountModalComponent implements OnInit {

  @Input() accountEmail: string;
  @Input() safeAccountVerification: boolean = false;

  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Output() accountVerified: EventEmitter<Verification> = new EventEmitter();

  verificationUuid: string;

  emailVerified: boolean;

  constructor(private countdownService: CountdownService) {
  }

  ngOnInit(): void {
    this.emailVerified = this.countdownService.emailVerified;
    this.verificationUuid = this.countdownService.verificationUuid;
  }

  onEmailVerified(data: any): void {
    this.accountEmail = data.email;
    this.verificationUuid = data.verification_uuid;
    this.emailVerified = true;
    this.countdownService.countdown(data.verification_uuid);
  }

  onVerificationVerified(verification: Verification): void {
    this.accountVerified.emit(verification);
  }

  cancel(): void {
    this.canceled.emit();
  }

}
