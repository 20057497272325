import { Adapter } from './adapter';

type NoParamConstructor<T> = new (item: any) => T;

export class BaseAdapter<T> implements Adapter<T> {
  public constructor(private ctor: NoParamConstructor<T>) {
  }

  adapt(data: any): T {
    let item = null;

    if (data && data.data && data.data.data) {
      item = data.data.data;
    } else if (data && data.data) {
      item = data.data;
    } else if (data) {
      item = data;
    }

    if (item && data.message === 'Redirect') {
      item.redirect = true;
      item.redirectUrl = data.redirectUrl;
      item.statusCode = data.statusCode;
    }

    return new this.ctor(item);
  }

  adaptArray(items: any): T[] {
    const response = [];

    if (items && items.data && items.data.data) {
      items = items.data.data;
    } else if (items && items.data) {
      items = items.data;
    }

    if (items) {
      items.forEach((item) => {
        response.push(this.adapt(item));
      });
    }

    return response;
  }
}
