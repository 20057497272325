import { Injectable } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';
import { DateTime } from 'luxon';
import { environment } from 'src/environments/environment';

export class Transaction extends Resource<Request> {

    payment_processor_initial_response_data: any;
    payment_processor_response_data: any;
    is_3dsecure: boolean;
    amount: number;
    credit_card_type: string;
    card_holder: string;
    masked_pan: string;
    auth_code?: string;
    created_at: string;
    createdAtDate?: Date;
    status: string;
    errors: any[];
    code: string;

    public constructor(data?: {}) {
        super(data);
    }

    is3DSecure() {
      if (!this.payment_processor_initial_response_data) {
        return false;
      }

      return (
        this.status === 'PENDING' &&
        this.is_3dsecure &&
        this.payment_processor_initial_response_data.redirect_url
      );
    }

    getRedirectUrl() {
      return this.payment_processor_initial_response_data?.redirect_url;
    }

    getSuccessUrl(): string {
      return environment.websiteUrl + '?transaction_code=' + this.code;
    }
}

@Injectable({
    providedIn: 'root'
})
export class TransactionAdapter extends BaseAdapter<Transaction> {
    constructor() {
        super(Transaction);
    }

    adapt(data: any): Transaction {
        const item = super.adapt(data);

        if (item.payment_processor_response_data?.returnData?.cardHolder) {
            item.card_holder = item.payment_processor_response_data.returnData.cardHolder;
        }
        if (item.payment_processor_response_data?.errors) {
            item.errors = item.payment_processor_response_data.errors;
        }

        if (item.payment_processor_response_data?.extraData?.authCode) {
            item.auth_code = item.payment_processor_response_data.extraData.authCode;
        } else {
            item.auth_code = '';
        }

        if (item.created_at) {
          item.createdAtDate = DateTime.fromSQL(item.created_at, {zone: 'UTC'}).toJSDate();
        }

        return item;
    }

}
