import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ApiData } from '../../../Library/api-data/api-data.model';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ApiError } from '../../../Library/api-data/api-error.model';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CardService {

  url: string = environment.apiUrl + '/cards';

  constructor(private httpClient: HttpClient) {
  }

  detach(id: number, data?: {}): Observable<any> {
    return this.httpClient.post<ApiData<any>>(`${this.url}/${id}/detach`, data).pipe(
      catchError((error) => {
        error = new ApiError(error.error);
        return throwError(error);
      }),
      map((response: ApiData<any>) => {
        return response;
      })
    );
  }
}
