import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ResourceService } from '../../../Library/resource/resource.service';
import { Transaction, TransactionAdapter } from '../models/transaction.model';

@Injectable()

export class TransactionService extends ResourceService<Transaction> {

  constructor(httpClient: HttpClient, adapter: TransactionAdapter) {
    super('/transactions', httpClient, adapter);
  }

}
