import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLayoutComponent } from './@shared/layouts/app-layout/app-layout.component';
import { currentLocale, routes as routesTrans } from './Library/routes';
import { HomepageComponent } from './@modules/homepage/components/homepage/homepage.component';
import { SafetyComponent } from './@modules/safety/components/safety/safety.component';
import { FaqComponent } from './@modules/faq/components/faq/faq.component';
import { TermsComponent } from './@modules/terms/components/terms/terms.component';

const routes: Routes = [
    {
        path: '',
        component: AppLayoutComponent,
        children: [
            {
                path: '',
                component: HomepageComponent
            },
            {
                path: routesTrans[currentLocale].safety,
                component: SafetyComponent
            },
            {
                path: routesTrans[currentLocale].faq,
                component: FaqComponent
            },
            {
                path: routesTrans[currentLocale].termsAndConditions,
                component: TermsComponent
            },
            {
                path: '**',
                component: HomepageComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabledBlocking',
        relativeLinkResolution: 'legacy'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
