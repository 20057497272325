import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class UUIDInterceptor implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID) public platformID: string,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (isPlatformBrowser(this.platformID)) {
      const isApiUrl = request.url.startsWith(environment.apiUrl);
      if (isApiUrl) {
        request = request.clone({
          setHeaders: {
            'fl-website': 'true',
          },
        });
      }
    }

    return next.handle(request);
  }
}
