import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ApiData } from '../../../Library/api-data/api-data.model';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ApiError } from '../../../Library/api-data/api-error.model';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  url: string = environment.apiUrl + '/accounts';

  constructor(private httpClient: HttpClient) {
  }

  verifyAccount(email: string): Observable<string> {
    return this.httpClient.post<ApiData<any>>(`${this.url}/verify`, {email}).pipe(
      catchError((error) => {
        error = new ApiError(error.error);
        return throwError(error);
      }),
      map((response) => {
        return response.data.verification_uuid;
      })
    );
  }
}
