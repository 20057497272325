import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Bill } from '../../../bill/models/bill.model';
import { BillService } from '../../../bill/services/bill.service';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { currentLocale, routes as routesTrans } from '../../../../Library/routes';
import { environment } from '../../../../../environments/environment';
import { BillingAddress } from '../../../billing-address/models/billing-address.model';
import { TransactionService } from '../../../transaction/services/transaction.service';
import { ApiError } from '../../../../Library/api-data/api-error.model';
import { BillingAddressService } from '../../../billing-address/services/billing-address.service';
import { GlobalService } from '../../../../@shared/services/global.service';
import { isPlatformBrowser } from '@angular/common';
import { Transaction } from '../../../transaction/models/transaction.model';
import { AllsecureScriptLoaderService } from '../../../allsecure/services/allsecure-script-loader.service';
import { Verification } from '../../../verification/models/verification.model';
import { Account } from '../../../account/models/account.model';
import Bugsnag from '@bugsnag/js';

declare let PaymentJs: any;

@Component({
  selector: 'app-homepage-payment-form',
  templateUrl: './homepage-payment-form.component.html',
  styleUrls: ['./homepage-payment-form.component.scss']
})
export class HomepagePaymentFormComponent implements OnInit {

  bill: Bill;

  form: FormGroup;

  countries: any[];
  defaultCountry: any;

  accountModalOpen: boolean = false;
  detachCardModalOpen: boolean = false;

  payment: any;

  loading: boolean = false;
  validationError: boolean = false;
  cardError: boolean = false;
  cvvError: boolean = false;
  dateError: boolean = false;
  apiError: boolean = false;

  savedCardSelected: boolean = false;

  verification_uuid: string;
  account: Account;

  formattedAmount: string;
  termsRoute: string = `/${routesTrans[currentLocale].termsAndConditions}`;

  constructor(
      @Inject(PLATFORM_ID) private platformId,
      private billService: BillService,
      private globalService: GlobalService,
      private transactionService: TransactionService,
      private billingAddressService: BillingAddressService,
      private allSecureScriptLoader: AllsecureScriptLoaderService) {
  }

  ngOnInit(): void {
    this.countries = this.globalService.getGlobalCountries();
    this.defaultCountry = this.globalService.getGlobalCountryByCode('ME');

    this.billService.bill.subscribe(bill => {
      this.bill = bill;

      this.initForm();

      this.allSecureScriptLoader.init(isLoaded => {
        if (isLoaded) {
          this.initPayment();
        } else {
          console.error('Error loading payment script');
        }
      });

    });
  }

  initForm(): void {
    this.form = new FormGroup({
      transaction_token: new FormControl(''),
      pay_amount: new FormControl(this.bill?.amount ? this.bill.amount / 100 : 0),
      name: new FormControl('', [Validators.required]),
      surname: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      country: new FormControl(this.defaultCountry.code),
      card_full_name: new FormControl('', [Validators.required]),
      expiry_date: new FormControl('', [
        Validators.required,
        Validators.pattern('((0[1-9])|(1[0-2]))/([0-9][0-9])')
      ]),
      save_account: new FormControl(false),
      overwrite_card: new FormControl(false),
      consent: new FormControl(false, Validators.requiredTrue)
    });

    this.formattedAmount = (this.bill.amount / 100).toLocaleString('fr');
    this.payAmount.valueChanges.subscribe(val => {
      if (this.payAmount.value) {
        this.formattedAmount = this.payAmount.value.toLocaleString('fr');
      }
    });
  }

  initPayment(): void {
    this.payment = new PaymentJs();
    this.payment.init(this.getAllSecureIntegrationKey(), 'card_number', 'card_cvv', paym => {

      const focusStyle = {
        'border-color': '#0D569D',
        outline: 'none'
      };

      const style = {
        height: '50px',
        'font-size': '20px',
        width: '100%',
        color: '#000',
        'background-color': '#FFFFFF',
        'border-color': '#cdcdcd',
        'border-style': 'solid',
        'border-width': '1px',
        'font-family': '\'Inter\', sans-serif',
        transition: 'all ease-in-out .15s',
        'border-radius': '6px',
        padding: '0 16px'
      };

      paym.setNumberStyle(style);
      paym.setCvvStyle(style);

      paym.numberOn('focus', () => {
        paym.setNumberStyle(focusStyle);
      });
      paym.cvvOn('focus', () => {
        paym.setCvvStyle(focusStyle);
      });

      paym.numberOn('blur', () => {
        paym.setNumberStyle(style);
      });
      paym.cvvOn('blur', () => {
        paym.setCvvStyle(style);
      });

      paym.setCvvPlaceholder('xxx');
    });
  }

  onSubmit(): void {
    this.validationError = !this.form.valid;

    if (this.validationError) {
      this.form.markAllAsTouched();
      this.scrollToFirstInvalidInput();
      return;
    }

    if (this.saveAccount.value === true && !this.verification_uuid) {
      this.openAccountModal();
      return;
    }

    if (this.overwriteCard.value === true) {
      this.openDetachCardModal();
      return;
    }

    if (this.savedCardSelected) {
      this.processTransactionWithSavedCard();
    } else {
      this.tokenizePayment();
    }
  }

  processTransactionWithSavedCard(): void {
    this.loading = true;

    const billingAddress = new BillingAddress({
      first_name: this.name.value,
      last_name: this.surname.value,
      address: this.address.value,
      city: this.city.value,
      country: this.country.value
    });

    const transactionData: any = {
      card_holder: this.cardFullName.value,
      amount: this.payAmount.value * 100,
      bill: this.bill,
      billing_address: billingAddress,
      card_on_file: 1,
      verification_uuid: this.verification_uuid
    };

    this.transactionService.save(transactionData).subscribe((data: Transaction) => {
      Bugsnag.leaveBreadcrumb('processTransactionWithSavedCard(): ' + JSON.stringify(data));

          if (data.status === 'SUCCESSFUL') {
            window.location.href = data.getSuccessUrl();
            return;
          }

          if (data.status === 'FAILED') {
            this.apiError = true;
            this.loading = false;
            return;
          }

          if (data.is3DSecure()) {
            window.location.href = data.getRedirectUrl();
            return;
          }

          Bugsnag.notify('processTransactionWithSavedCard(): unexpected transaction status');
        },
        (error: ApiError) => {
          this.apiError = true;
          this.loading = false;
        });
  }

  tokenizePayment(): void {
    const tokenizeData = {
      card_holder: this.cardFullName.value,
      month: this.expiryDate.value.substring(0, 2),
      year: this.form.get('expiry_date').value.substring(3, 5),
      email: this.bill.client_email
    };

    this.loading = true;

    this.payment.tokenize(
        tokenizeData,
        (token, cardData) => {

          this.cardError = this.cvvError = this.dateError = false;

          this.transactionToken.setValue(token);

          const billingAddress = new BillingAddress({
            first_name: this.name.value,
            last_name: this.surname.value,
            address: this.address.value,
            city: this.city.value,
            country: this.country.value
          });

          const transactionData: any = {
            card_holder: this.cardFullName.value,
            amount: this.payAmount.value * 100,
            payment_processor_data: {
              transactionToken: this.transactionToken.value
            },
            bill: this.bill,
            billing_address: billingAddress
          };

          if (this.verification_uuid) {
            transactionData.verification_uuid = this.verification_uuid;

            if (this.saveAccount.value === true || this.overwriteCard.value === true) {
              transactionData.save_card = 1;
            } else {
              transactionData.save_card = 0;
            }
          }

          this.transactionService.save(transactionData).subscribe((data: Transaction) => {
            Bugsnag.leaveBreadcrumb('tokenizePayment(): ' + JSON.stringify(data));

                if (data.status === 'SUCCESSFUL') {
                  window.location.href = data.getSuccessUrl();
                  return;
                }

                if (data.status === 'FAILED') {
                  this.apiError = true;
                  this.loading = false;
                  return;
                }

                if (data.is3DSecure()) {
                  window.location.href = data.getRedirectUrl();
                  return;
                }

                Bugsnag.notify('tokenizePayment(): unexpected transaction status');
              },
              (error: ApiError) => {
                this.apiError = true;
                this.loading = false;
              });
        },
        (errors) => {
          this.cardError = this.cvvError = this.dateError = false;

          for (const error of errors) {
            if (error.attribute === 'number') {
              this.cardError = true;
            } else if (error.attribute === 'cvv') {
              this.cvvError = true;
            } else if (error.attribute === 'month' || error.attribute === 'year') {
              this.dateError = true;
            }
          }

          this.loading = false;
        }
    );
  }

  getAllSecureIntegrationKey(): string {
    return environment.allSecureIntegrationKey;
  }

  modifyExpiryDate(event): void {
    const value = event.target.value;
    const backspace = event.key === 'Backspace';
    let newValue = '';

    if (value.length === 2 && !backspace) {
      newValue = value + '/';
    } else if (value.length === 2 && backspace) {
      newValue = value.slice(0, 1);
    } else {
      return;
    }

    this.expiryDate.setValue(newValue);
  }

  scrollToFirstInvalidInput(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const form: HTMLElement = document.getElementById('form');
    const firstInvalidControl: Element = form.getElementsByClassName('ng-invalid')[0];
    firstInvalidControl.scrollIntoView();
    (firstInvalidControl as HTMLElement).focus();
  }

  openAccountModal(): void {
    this.accountModalOpen = true;
  }

  openDetachCardModal(): void {
    this.detachCardModalOpen = true;
  }

  detachCardModalCanceled(): void {
    this.detachCardModalOpen = false;
  }

  accountModalCanceled(): void {
    this.accountModalOpen = false;
  }

  newAccountVerified(verification: Verification): void {
    this.accountModalOpen = false;
    this.verification_uuid = verification.uuid;
    this.tokenizePayment();
  }

  savedAccountVerified(verification: Verification): void {
    this.verification_uuid = verification.uuid;
    this.account = verification.account;

    this.updateFormWithSavedData(this.account);
  }

  updateFormWithSavedData(account: Account): void {
    this.name.setValue(account.first_name);
    this.surname.setValue(account.last_name);
    this.address.setValue(account.address);
    this.city.setValue(account.city);
    this.country.setValue(account.country ? account.country : 'ME');
  }

  onCardSelectorChanged(savedCardSelected: boolean): void {
    this.savedCardSelected = savedCardSelected;

    if (this.savedCardSelected) {
      this.clearCardValidators();

      this.saveAccount.setValue(false);
      this.overwriteCard.setValue(false);
    } else {
      this.setCardValidators();
    }
  }

  clearCardValidators(): void {
    this.cardFullName.clearValidators();
    this.cardFullName.updateValueAndValidity();
    this.expiryDate.clearValidators();
    this.expiryDate.updateValueAndValidity();
  }

  setCardValidators(): void {
    this.cardFullName.setValidators([Validators.required]);
    this.cardFullName.updateValueAndValidity();
    this.expiryDate.setValidators([
      Validators.required,
      Validators.pattern('((0[1-9])|(1[0-2]))/([0-9][0-9])')
    ]);
    this.expiryDate.updateValueAndValidity();
  }

  onCardDetached(): void {
    this.account.card = null;
    this.savedCardSelected = false;
    this.setCardValidators();
  }

  onCardOverwrite(): void {
    this.detachCardModalOpen = false;
    this.tokenizePayment();
  }

  // FormControl getters

  get transactionToken(): AbstractControl {
    return this.form.get('transaction_token');
  }

  get payAmount(): AbstractControl {
    return this.form.get('pay_amount');
  }

  get name(): AbstractControl {
    return this.form.get('name');
  }

  get surname(): AbstractControl {
    return this.form.get('surname');
  }

  get address(): AbstractControl {
    return this.form.get('address');
  }

  get city(): AbstractControl {
    return this.form.get('city');
  }

  get country(): AbstractControl {
    return this.form.get('country');
  }

  get saveAccount(): AbstractControl {
    return this.form.get('save_account');
  }

  get overwriteCard(): AbstractControl {
    return this.form.get('overwrite_card');
  }

  get cardFullName(): AbstractControl {
    return this.form.get('card_full_name');
  }

  get expiryDate(): AbstractControl {
    return this.form.get('expiry_date');
  }

  get consent(): AbstractControl {
    return this.form.get('consent');
  }

}
