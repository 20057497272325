import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from '../../../../@shared/services/seo/seo.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-safety',
    templateUrl: './safety.component.html',
    styleUrls: ['./safety.component.scss']
})
export class SafetyComponent implements OnInit, AfterViewInit {
    browser_photo: string = '';
    isMobileLayout: boolean = null;

    constructor(private seoService: SeoService,
                private activatedRoute: ActivatedRoute,
                @Inject(PLATFORM_ID) private platformId) {
    }

    ngOnInit(): void {
        this.isMobileLayout = window.innerWidth <= 768;

        if (isPlatformBrowser(this.platformId)) {
            this.setImage();

            window.addEventListener('resize', () => {
                this.isMobileLayout = window.innerWidth <= 768;
            });
        }
    }

    ngAfterViewInit(): void {
        this.seoService.refreshForRoute(this.activatedRoute.snapshot);
    }

    setImage(): void {
        const browser = this.detectBrowserName();

        if (this.isMobileLayout) {
            if (browser === 'chrome') {
                this.browser_photo = '/assets/img/browser_photo/chrome-mobile.png';
            } else {
                this.browser_photo = '/assets/img/browser_photo/safari-mobile.png';
            }
        } else {
            if (browser === 'firefox') {
                this.browser_photo = '/assets/img/browser_photo/firefox.png';
            } else if (browser === 'edge') {
                this.browser_photo = '/assets/img/browser_photo/edge.png';
            } else if (this.browser_photo === 'safari') {
                this.browser_photo = '/assets/img/browser_photo/safari-desktop.png';
            } else {
                this.browser_photo = '/assets/img/browser_photo/chrome-desktop.png';
            }
        }
    }

    detectBrowserName(): string {
        const agent = window.navigator.userAgent.toLowerCase();
        switch (true) {
            case agent.indexOf('edge') > -1:
                return 'edge';
            case agent.indexOf('opr') > -1 && !!(window as any).opr:
                return 'opera';
            case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
                return 'chrome';
            case agent.indexOf('trident') > -1:
                return 'ie';
            case agent.indexOf('firefox') > -1:
                return 'firefox';
            case agent.indexOf('safari') > -1:
                return 'safari';
            default:
                return 'other';
        }
    }
}
