import { AfterViewInit, Component, Inject, LOCALE_ID, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../../../Library/compontents/base.component';
import { isPlatformBrowser } from '@angular/common';
import { BillService } from '../../../bill/services/bill.service';
import { Bill } from '../../../bill/models/bill.model';
import { SeoService } from '../../../../@shared/services/seo/seo.service';
import { LocaleService } from '../../../../@shared/services/locale.service';

@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html'
})
export class HomepageComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    isMobileLayout: boolean = null;
    bill: Bill;

    constructor(
        activatedRoute: ActivatedRoute,
        seoService: SeoService,
        private router: Router,
        private localeService: LocaleService,
        private billService: BillService,
        @Inject(LOCALE_ID) public locale: string,
        @Inject(PLATFORM_ID) public platformId
    ) {
        super(activatedRoute, seoService, locale, platformId);
        this.localeService.setCurrentRouteTranslationsForItem();
    }

    ngOnInit(): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        this.isMobileLayout = window.innerWidth <= 768;
        window.addEventListener('resize', () => {
            this.isMobileLayout = window.innerWidth <= 768;
        });

        this.billService.bill.subscribe(bill => {
            this.bill = bill;
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngAfterViewInit(): void {
        this.seoService.refreshForRoute(this.activatedRoute.snapshot);
    }

}
