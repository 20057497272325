export const routes: any = {
  me: {
    safety: 'sigurnost',
    faq: 'cesta-pitanja',
    termsAndConditions: 'uslovi-koriscenja',
    contact: 'kontakt',
    page: '',
  },
  en: {
    safety: 'safety',
    faq: 'frequently-asked-questions',
    termsAndConditions: 'terms-and-conditions',
    contact: 'contact',
    page: '',
  },
};

export enum RouteName {
  HOME = 'homepage',
  FAQ = 'faq',
  TERMS = 'termsAndConditions'
}

export let localeMapping: {} = {
  'sr-Latn-ME': 'me',
  'en-US': 'en',
  en: 'en',
};

export let currentLocale: string = $localize`:@@locale_code_sr_or_en|CurrentLocaleShortCode|CurrentLocaleShortCode:me`;
