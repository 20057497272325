<form id="form" [formGroup]="form" (ngSubmit)="onSubmit()" class="sm:px-5">
  <input type="hidden" formControlName="transaction_token" id="transaction_token"/>

  <div class="container md:px-6">
    <span class="hidden sm:block mb-6 text-20 font-700">
      Plaćanje računa
    </span>

    <label for="pay_amount" class="input-label !text-16 font-700">
      Želim da uplatim
    </label>
    <div class="mb-8">
      <div class="flex flex-row items-center border border-gray-300 rounded-[6px] p-4">
        <input id="pay_amount"
               formControlName="pay_amount"
               type="number"
               min="0.01"
               step=".01"
               placeholder="Unesite iznos"
               class="w-full text-36 placeholder:text-gray-300 font-700 border-0 focus:ring-0 p-0 caret-brand-400 flex-1 text-right"/>
        <span class="text-34 font-700">€</span>
      </div>
      <div *ngIf="!payAmount.valid && payAmount.touched" class="w-full">
        <span class="input-error-message">
          Molimo unesite ispravan iznos
        </span>
      </div>
    </div>
  </div>

  <div class="w-full sm:rounded-2 bg-gray-100 sm:border sm:border-gray-200 px-6 py-7 mb-9">
    <div class="container">

      <app-homepage-get-saved-account
          [clientEmail]="bill.client_email"
          (savedAccountVerified)="savedAccountVerified($event)">
      </app-homepage-get-saved-account>

      <span class="block mt-8 sm:mt-12 mb-[18px] text-18 font-700">
				Podaci o platiocu:
			</span>

      <div class="w-full flex flex-col md:flex-row gap-5 md:gap-3 mb-3">
        <div class="flex-1">
          <label for="name" class="input-label">
            Ime
          </label>
          <input id="name"
                 formControlName="name"
                 type="text"
                 placeholder="Ime"
                 size="10"
                 class="input-field"/>
          <span *ngIf="!name.valid && name.touched"
                class="input-error-message">
						Polje je obavezno
					</span>
        </div>
        <div class="flex-1">
          <label for="surname" class="input-label">
            Prezime
          </label>
          <input id="surname"
                 formControlName="surname"
                 type="text"
                 placeholder="Prezime"
                 size="10"
                 class="input-field"/>
          <span *ngIf="!surname.valid && surname.touched"
                class="input-error-message">
						Polje je obavezno
					</span>
        </div>
      </div>

      <div class="mb-5">
        <label for="address" class="input-label">
          Adresa
        </label>
        <input id="address"
               formControlName="address"
               type="text"
               placeholder="Adresa"
               class="input-field"/>
        <span *ngIf="!address.valid && address.touched"
              class="input-error-message">
					Polje je obavezno
				</span>
      </div>

      <div class="mb-5">
        <label for="city" class="input-label">
          Grad
        </label>
        <input id="city"
               formControlName="city"
               type="text"
               placeholder="Grad"
               class="input-field"/>
        <span *ngIf="!city.valid && city.touched"
              class="input-error-message">
					Polje je obavezno
				</span>
      </div>

      <div class="mb-5">
        <label for="country" class="input-label">
          Država
        </label>
        <select id="country"
                formControlName="country"
                type="text"
                placeholder="Država"
                class="input-field">
          <option *ngFor="let country of countries" value="{{country.code}}">
            {{ country.name }}
          </option>
        </select>
      </div>

      <div class="w-full mb-8">
        <span class="block text-18 font-700">
          Podaci o kartici:
        </span>

        <div class="mt-4" *ngIf="account?.card">
          <app-homepage-card-selector
              [card]="account?.card"
              [verification_uuid]="verification_uuid"
              (cardSelectorChanged)="onCardSelectorChanged($event)"
              (cardDetached)="onCardDetached()">
          </app-homepage-card-selector>
        </div>

        <div class="mt-3 md:mt-2" [ngClass]="{'hidden': account && account.card && savedCardSelected}">
          <div class="mb-6 flex items-center gap-3">
            <div class="bg-white border border-gray-300 rounded-1">
              <img src="assets/img/logos/visa.png" width="72" height="41" alt="VISA">
            </div>
            <div class="bg-white border border-gray-300 rounded-1">
              <img src="assets/img/logos/mastercard.png" width="72" height="41" alt="Mastercard">
            </div>
          </div>

          <label for="card_number" class="input-label">
            Broj kartice:
          </label>
          <div class="relative mb-5">
            <div id="card_number" style="height: 50px;"></div>
            <span *ngIf="cardError"
                  class="input-error-message"
            >
              Unesite ispravan broj katrice
            </span>
          </div>

          <div class="mb-5">
            <label for="card_full_name" class="input-label">
              Ime na kartici:
            </label>
            <input id="card_full_name"
                   formControlName="card_full_name"
                   type="text"
                   placeholder="Ime i prezime"
                   class="input-field"/>
            <span *ngIf="!cardFullName.valid && cardFullName.touched"
                  class="input-error-message">
              Polje je obavezno
            </span>
          </div>

          <div class="w-full flex flex-col md:flex-row gap-5 md:gap-3 mb-6">
            <div class="flex-1">
              <label for="expiry_date" class="input-label">
                Datum isteka kartice:
              </label>
              <input id="expiry_date"
                     formControlName="expiry_date"
                     type="text"
                     placeholder="mm/gg"
                     size="10"
                     class="input-field"
                     (keyup)="modifyExpiryDate($event)"/>
              <span *ngIf="dateError || (!expiryDate.valid && expiryDate.touched)"
                    class="input-error-message">
                Unesite ispravan datum isteka
              </span>
            </div>
            <div class="flex-1 relative">
              <label for="card_cvv" class="input-label">
                CVV:
              </label>
              <div id="card_cvv" style="height:50px;"></div>
              <span *ngIf="cvvError"
                    class="input-error-message">
                Unesite ispravan CVV
              </span>
            </div>
          </div>
        </div>

        <div *ngIf="!account || !account.card">
          <label class="checkbox-wrapper !pl-9">
            <input type="checkbox" id="save_account" formControlName="save_account">
            <span class="text-14 font-700 block pt-1">
              Sačuvaj moje podatke za buduće plaćanje
            </span>
            <span class="checkmark !w-7 !h-7 !border-2 after:!top-[6px] after:!left-[5px]"></span>
          </label>
        </div>

        <div *ngIf="account && account.card && !savedCardSelected">
          <label class="checkbox-wrapper !pl-9">
            <input type="checkbox" id="overwrite_card" formControlName="overwrite_card">
            <span class="text-14 font-700 block pt-1">
              Sačuvaj umjesto postojeće
            </span>
            <span class="checkmark !w-7 !h-7 !border-2 after:!top-[6px] after:!left-[5px]"></span>
          </label>
        </div>

      </div>
    </div>
  </div>

  <div class="container">
    <label class="checkbox-wrapper">
      <input type="checkbox" id="consent" formControlName="consent">
      <span class="text-14 block pt-[2px]">
				<span>Saglasan sam sa ponudom i prihvatam</span>
          <a [routerLink]="termsRoute" target="_blank" class="text-brand-400 underline hover:no-underline">
            Uslove korišćenja
          </a>
			  </span>
      <span class="checkmark"></span>
    </label>
    <span *ngIf="!consent.valid && consent.touched"
          class="input-error-message -mt-1 mb-1">
      Morate prihvatiti uslove korišćenja
    </span>

    <button [disabled]="loading"
            class="mt-5 mb-3 w-full btn-primary">
      Plati {{ formattedAmount }}€
    </button>
    <span *ngIf="validationError"
          class="input-error-message -mt-3">
      Molimo unesite ispravne vrijednosti za sva polja.
    </span>
    <span *ngIf="apiError"
          class="input-error-message -mt-3">
      Došlo je do neočekivane greške, molimo pokušajte kasnije.
    </span>

    <div class="h-6 w-full flex items-center justify-center">
      <app-loader *ngIf="loading"></app-loader>
    </div>

    <span class="text-12 text-gray-400">
      Nakon unosa podataka o kartici, molimo Vas da kliknete na Plati i sačekate da sistem obradi transakciju. Ukoliko je Vaša kartica prijavljena na 3D-Secure program, možda će biti potrebno da unesete dodatne informacije koje zahtijeva Vaša banka (najčesće lozinka). U slučaju da ne znate lozinku, predlažemo Vam da prvo kontaktirate svoju banku.
    </span>
  </div>
</form>

<div *ngIf="accountModalOpen">
  <app-account-modal [accountEmail]="bill.client_email"
                     [safeAccountVerification]="false"
                     (canceled)="accountModalCanceled()"
                     (accountVerified)="newAccountVerified($event)">
  </app-account-modal>
</div>

<div *ngIf="detachCardModalOpen">
  <app-detach-card-modal
      [card]="account.card"
      [verification_uuid]="verification_uuid"
      (canceled)="detachCardModalCanceled()"
      (cardDetached)="onCardOverwrite()">
  </app-detach-card-modal>
</div>


