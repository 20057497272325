import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/@shared/shared.module';

import { HomepageComponent } from './components/homepage/homepage.component';
import { HomepageRoutingModule } from './homepage-routing.module';
import { RouterModule } from '@angular/router';
import { BillingInfoModule } from '../billing-info/billing-info.module';
import { HomepageCallCenterComponent } from './components/homepage-call-center/homepage-call-center.component';
import { HomepagePaymentFormComponent } from './components/homepage-payment-form/homepage-payment-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TransactionModule } from '../transaction/transaction.module';
import { HomepageTransactionStatusComponent } from './components/homepage-transaction-status/homepage-transaction-status.component';
import {
  HomepageBillAmountAndDueDateComponent
} from './components/homepage-bill-amount-and-due-date/homepage-bill-amount-and-due-date.component';
import { HomepageGetSavedAccountComponent } from './components/homepage-get-saved-account/homepage-get-saved-account.component';
import { AccountModalEmailComponent } from './modals/account-modal/components/account-modal-email/account-modal-email.component';
import { AccountModalComponent } from './modals/account-modal/components/account-modal/account-modal.component';
import {
  AccountModalVerificationCodeComponent
} from './modals/account-modal/components/account-modal-verification-code/account-modal-verification-code.component';
import {
  AccountModalCountdownComponent
} from './modals/account-modal/components/account-modal-countdown/account-modal-countdown.component';
import { HomepageCardSelectorComponent } from './homepage-card-selector/homepage-card-selector.component';
import { DetachCardModalComponent } from './modals/detach-card-modal/detach-card-modal.component';

@NgModule({
  declarations: [
    HomepageComponent,
    HomepageCallCenterComponent,
    HomepagePaymentFormComponent,
    HomepageTransactionStatusComponent,
    HomepageBillAmountAndDueDateComponent,
    HomepageGetSavedAccountComponent,
    AccountModalComponent,
    AccountModalEmailComponent,
    AccountModalVerificationCodeComponent,
    AccountModalCountdownComponent,
    HomepageCardSelectorComponent,
    DetachCardModalComponent
  ],
  imports: [
    CommonModule,
    HomepageRoutingModule,
    SharedModule,
    RouterModule,
    BillingInfoModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    TransactionModule
  ],
  providers: []
})
export class HomepageModule {
}
