import { Component, OnInit } from '@angular/core';
import { currentLocale, routes as routesTrans } from '../../../Library/routes';
import { faHeart } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

    currentYear: number;

    faqRoute: string = `/${routesTrans[currentLocale].faq}`;
    termsRoute: string = `/${routesTrans[currentLocale].termsAndConditions}`;

    faHeart = faHeart;

    constructor() {
    }

    ngOnInit(): void {
        this.currentYear = new Date().getFullYear();
    }
}
