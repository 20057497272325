import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '../../../../../account/services/account.service';

@Component({
  selector: 'app-account-modal-email',
  templateUrl: './account-modal-email.component.html'
})
export class AccountModalEmailComponent implements OnInit {

  @Input() clientEmail: string;
  @Input() safeAccountVerification: boolean;
  @Output() emailVerified: EventEmitter<{
    email: string,
    verification_uuid: string
  }> = new EventEmitter();

  emailForm: FormGroup;

  loading: boolean = false;
  emailError: boolean = false;

  constructor(private accountService: AccountService) {
  }

  ngOnInit(): void {
    this.emailForm = new FormGroup({
      email: new FormControl(this.clientEmail ? this.clientEmail : '', [Validators.required, Validators.email])
    });
  }

  onSubmit(): void {
    this.loading = true;
    this.accountService.verifyAccount(this.email.value).subscribe((verification_uuid: string) => {
      this.loading = false;
      this.emailVerified.emit({email: this.email.value, verification_uuid});
    }, error => {
      this.loading = false;
      this.emailError = true;
    });
  }

  get email(): AbstractControl {
    return this.emailForm.get('email');
  }
}
