import {Injectable} from '@angular/core';
import {Platform} from '../../../Library/platform/services/platform.service';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AllsecureScriptLoaderService {

  constructor(private platform: Platform) {
  }

  init(callback: CallableFunction): void {
    const scriptAttributes = [ { name: 'data-main', value: 'payment-js' }];
    this.platform.appendScriptToBody(this.getScriptUrl(), true, scriptAttributes).then(loaded => {
      callback(loaded);
    });
  }

  getScriptUrl(): string {
    if (environment.production) {
      return 'https://asxgw.com/js/integrated/payment.1.3.min.js';
    } else {
      return 'https://demo.paymentsandbox.cloud/js/integrated/payment.1.3.min.js';
    }
  }

}
