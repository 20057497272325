<div class="w-full sm:w-12/24 pt-12 pb-15 grid grid-cols-1 sm:grid-cols-2 gap-y-8 sm:gap-y-[22px]">
	<div class="sm:pr-2/24 md:pr-4/24">
		<span class="text-16 leading-122 block mb-3">
			PODACI O TRGOVCU:
		</span>
		<span class="text-16 leading-122 font-700 block mb-2">
			Elektroprivreda Crne Gore AD Nikšić
		</span>
		<span class="text-16 leading-122 block">
			Ulica Vuka Karadžića br. 2
		</span>
		<span class="text-16 leading-122 block mb-4 sm:mb-6">
			81400 Nikšić
		</span>
		<span class="text-16 leading-122 block">
			PIB: <span class="font-700">02002230</span>
		</span>
		<span class="text-16 leading-100">
			PDV: <span class="font-700">20/31-00112-1</span>
		</span>
	</div>
	<div class="sm:pr-2/24 md:pr-4/24">
		<span class="text-16 leading-122 block mb-3">
			KONTAKT ZA KUPCE:
		</span>
		<span class="text-16 leading-122 font-700 block">
			19100
		</span>
		<span class="text-16 leading-100 font-700">
			(ovaj broj je besplatan)
		</span>
	</div>
</div>
