import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SeoItem } from './seo-item.model';
import { RouteName } from '../../../Library/routes';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoGeneralService {

  private defaultText: string = '';
  private currentRoute: RouteName = null;
  private currentObject: any = null;
  private seoItem: SeoItem = null;

  constructor(
    private titleService: Title,
    private metaService: Meta,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private locale: string
  ) {
  }

  setCurrentObject(currentObject): void {
    this.currentObject = currentObject;
  }

  setSeoItem(seoItem: SeoItem): void {
    this.seoItem = seoItem;
  }

  reset(): void {
    this.titleService.setTitle('');
    this.metaService.updateTag({name: 'description', content: ''});
    this.metaService.removeTag('name="keywords"');
  }

  refresh(seoItem: SeoItem): void {
    this.titleService.setTitle(seoItem.getTitle());
    this.metaService.updateTag({name: 'description', content: seoItem.getDescription()});

    if (seoItem.keywords) {
      this.metaService.updateTag({name: 'keywords', content: seoItem.keywords});
    }
  }

  refreshCanonicalUrl(): void {
    // Get the URL
    let canonicalUrl;
    if (this.seoItem && this.seoItem.canonical_url) {
      canonicalUrl = this.seoItem.canonical_url;
    } else {
      let url: string = this.document.URL;
      url = url.replace('http://', 'https://');
      canonicalUrl = url.indexOf('?') > -1 ? url.substring(0, url.indexOf('?')) : url;
    }

    // Remove the current HTML tag
    const currentTagElements = this.document.querySelectorAll('link[rel="canonical"]');
    currentTagElements.forEach((element) => {
      element.remove();
    });

    // Create a new HTML tag in the <head>
    const link = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', canonicalUrl);
    this.document.head.appendChild(link);
  }

  setAlternateUrl(item: any, locale: string): void {
    const newhtmlLinkElement = this.document.createElement('link');
    newhtmlLinkElement.setAttribute('rel', 'alternate');
    newhtmlLinkElement.setAttribute('hreflang', locale);
    let url = environment.websiteUrl;
    url += item.url;
    newhtmlLinkElement.setAttribute('href', url);
    this.document.head.appendChild(newhtmlLinkElement);
  }

  removeAlternateUrls(): void {
    const srLatnMe = this.document.querySelectorAll('[rel="alternate"][hreflang="sr-Latn-ME"]');
    const en = this.document.querySelectorAll('[rel="alternate"][hreflang="en"]');
    const defaultHreflang = this.document.querySelectorAll('[rel="alternate"][hreflang="x-default"]');
    srLatnMe.forEach((element) => {
      element.remove();
    });
    en.forEach((element) => {
      element.remove();
    });
    defaultHreflang.forEach((element) => {
      element.remove();
    });
  }
}
