<div class="w-full rounded-2 flex flex-col">
	<span class="block text-14 sm:text-16 leading-122 font-700">
		Novi Call Centar 19100
	</span>
	<span class="text-14 sm:text-16 leading-100">
		od sada Vam je na usluzi:
	</span>
	<div class="mt-4 flex flex-col gap-3">
		<div class="flex gap-3 items-center">
			<img src="assets/img/gfx/gfx-phone.png" alt="24h telefon" width="36" height="36"/>
			<span class="text-14">
				<span class="font-700">
					24h
				</span>
				dnevno putem govornog automata, ili
			</span>
		</div>
		<div class="flex gap-3 items-center">
			<img src="assets/img/gfx/gfx-operater.png" alt="operater" width="36" height="36" class="w-9 h-9"/>
			<span class="text-14">
				svakog radnog dana od
				<span class="font-700">
					08:00-20:00h
				</span>
				putem besplatnog kontakta sa operaterom
			</span>
		</div>
	</div>
</div>
