import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsComponent } from './components/terms/terms.component';
import { BillingInfoModule } from '../billing-info/billing-info.module';



@NgModule({
  declarations: [
    TermsComponent
  ],
    imports: [
        CommonModule,
        BillingInfoModule
    ]
})
export class TermsModule { }
