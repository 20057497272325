<div class="w-full flex flex-col">
  <span class="text-18 sm:text-24 leading-122 font-700 text-gray-700">
    Provjerite vaš email i unesite kod koji smo vam poslali
  </span>
  <span class="mt-3 text-16 leading-137 text-gray-400">
    Poslali smo kod na adresu
    <span class="font-700">{{ account_email }}</span>.
    <span class="hidden sm:inline">
      Ukoliko ne možete da ga pronađete, provjerite Spam folder.
    </span>
  </span>
  <div class="mt-4 sm:mt-7">
    <div class="code-field" [ngClass]="{'code-field--loading': loading, 'code-field--error': codeError}">
      <input type="text" pattern="\d*" maxlength="1" [disabled]="loading"/>
      <input type="text" pattern="\d*" maxlength="1" [disabled]="loading"/>
      <input type="text" pattern="\d*" maxlength="1" [disabled]="loading"/>
      <input type="text" pattern="\d*" maxlength="1" [disabled]="loading"/>
      <input type="text" pattern="\d*" maxlength="1" [disabled]="loading"/>
      <input type="text" pattern="\d*" maxlength="1" [disabled]="loading"/>
    </div>
    <span *ngIf="codeError" class="block mt-2 text-16 leading-122 font-700 text-status-red-400">
      Uneseni kod nije validan
    </span>
    <span *ngIf="accountAlreadyInUseError" class="block mt-2 text-16 leading-122 font-700 text-status-red-400">
      Korisnik sa ovom mail adresom već postoji
    </span>
  </div>
  <div class="mt-4">
    <app-account-modal-countdown
        [account_email]="account_email"
        (emailVerified)="updateVerificationUUID($event)">
    </app-account-modal-countdown>
  </div>
  <button type="button"
          (click)="submit()"
          [disabled]="loading"
          class="mt-7 w-full btn-primary">
    Dalje
  </button>
</div>
