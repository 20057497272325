import { RouteName } from './routes';

export const seoRules = {
  homepage: {
     me: {
      title: 'EPCG online plaćanje',
      seo_title: 'EPCG online plaćanje',
      seo_description: 'Platite Vaš posljednji račun za električnu energiju i izmirite prethodna dugovanja.',
      seo_fb_title: 'EPCG online plaćanje',
      seo_fb_description: 'Platite Vaš posljednji račun za električnu energiju i izmirite prethodna dugovanja.',
      seo_fb_image_url: '/assets/img/seo/epcg_seo.jpg',
      seo_fb_type: 'website',
      seo_tw_title: 'EPCG online plaćanje',
      seo_tw_description: 'Platite Vaš posljednji račun za električnu energiju i izmirite prethodna dugovanja.',
      seo_tw_image_url: '/assets/img/seo/epcg_seo.jpg',
      seo_keywords: 'home',
      description: 'Platite Vaš posljednji račun za električnu energiju i izmirite prethodna dugovanja.',
      route_name: RouteName.HOME,
      robots: 'noindex, follow',
      url: '',
    },
    en: {
      title: 'EPCG online payment',
      seo_title: 'EPCG online payment',
      seo_fb_title: 'EPCG Payments - Homepage',
      seo_fb_description: 'EPCG online payment',
      seo_fb_image_url: '/assets/img/seo/epcg_seo.jpg',
      seo_description: 'EPCG online payment',
      seo_tw_title: 'EPCG Payments - Homepage',
      seo_tw_description: 'EPCG online payment',
      seo_tw_image_url: '/assets/img/seo/epcg_seo.jpg',
      seo_fb_type: 'website',
      seo_keywords: 'home',
      description: 'EPCG online payment',
      route_name: RouteName.HOME,
      robots: 'noindex, follow',
      url: '',
    },
  },
  faq: {
     me: {
      title: 'Najčešća pitanja',
      seo_title: 'Najčešća pitanja',
      seo_description: 'Opis za česta pitanja.',
      seo_fb_title: 'EPCG Plaćanja - Najčešća pitanja',
      seo_fb_description: 'Opis za česta pitanja.',
      seo_fb_image_url: '/assets/img/seo/epcg_seo.jpg',
      seo_fb_type: 'website',
      seo_tw_title: 'EPCG Plaćanja - Najčešća pitanja',
      seo_tw_description: 'Opis za česta pitanja.',
      seo_tw_image_url: '/assets/img/seo/epcg_seo.jpg',
      seo_keywords: 'faq',
      description: 'Opis za česta pitanja.',
      route_name: RouteName.FAQ,
      robots: 'noindex, follow',
      url: '',
    },
    en: {
      title: 'Frequently Asked Questions',
      seo_title: 'Frequently Asked Questions',
      seo_fb_title: 'EPCG Payments - Frequently Asked Questions',
      seo_fb_description: 'Frequently Asked Questions description.',
      seo_fb_image_url: '/assets/img/seo/epcg_seo.jpg',
      seo_description: 'Frequently Asked Questions description.',
      seo_tw_title: 'EPCG Payments - Frequently Asked Questions',
      seo_tw_description: 'Frequently Asked Questions description.',
      seo_tw_image_url: '/assets/img/seo/epcg_seo.jpg',
      seo_fb_type: 'website',
      seo_keywords: 'faq',
      description: 'Frequently Asked Questions description.',
      route_name: RouteName.FAQ,
      robots: 'noindex, follow',
      url: '',
    },
  },
  termsAndConditions: {
     me: {
      title: 'Uslovi korišćenja i politika privatnosti',
      seo_title: 'Uslovi korišćenja i politika privatnosti',
      seo_description: 'Opis za uslove korišćenja.',
      seo_fb_title: 'EPCG Plaćanja - Uslovi korišćenja i politika privatnosti',
      seo_fb_description: 'Opis za uslove korišćenja.',
      seo_fb_image_url: '/assets/img/seo/epcg_seo.jpg',
      seo_fb_type: 'website',
      seo_tw_title: 'EPCG Plaćanja - Uslovi korišćenja i politika privatnosti',
      seo_tw_description: 'Opis za uslove korišćenja.',
      seo_tw_image_url: '/assets/img/seo/epcg_seo.jpg',
      seo_keywords: 'terms',
      description: 'Opis za uslove korišćenja.',
      route_name: RouteName.TERMS,
      robots: 'noindex, follow',
      url: '',
    },
    en: {
      title: 'Terms and Conditions & Privacy Policy',
      seo_title: 'Terms and Conditions & Privacy Policy',
      seo_fb_title: 'EPCG Payments - Terms and Conditions & Privacy Policy',
      seo_fb_description: 'Terms and Conditions & Privacy Policy description.',
      seo_fb_image_url: '/assets/img/seo/epcg_seo.jpg',
      seo_description: 'Terms and Conditions & Privacy Policy description.',
      seo_tw_title: 'EPCG Payments - Terms and Conditions & Privacy Policy',
      seo_tw_description: 'Terms and Conditions & Privacy Policy description.',
      seo_tw_image_url: '/assets/img/seo/epcg_seo.jpg',
      seo_fb_type: 'website',
      seo_keywords: 'terms',
      description: 'Terms and Conditions & Privacy Policy description.',
      route_name: RouteName.TERMS,
      robots: 'noindex, follow',
      url: '',
    },
  }
};
