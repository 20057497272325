<div class="w-full flex flex-col gap-4 sm:gap-1">
  <div class="w-full border-b border-gray-200 py-4 sm:py-3 flex flex-col md:flex-row items-start md:items-center md:justify-between gap-y-1 text-16">
    <span>
      Ukupni iznos Vašeg računa je za:
    </span>
    <span class="font-700">
      {{ bill.billing_month }} +
      <span>
        prethodna dugovanja
      </span>
    </span>
  </div>
  <div class="w-full border-b border-gray-200 pb-4 sm:py-6 flex flex-col sm:flex-row sm:items-center sm:justify-between gap-y-1">
    <span class="text-18 leading-122">
      Ukupno za uplatu:
    </span>
    <span class="text-22 sm:text-24 font-700 leading-122">
      {{ formattedAmount }}€
    </span>
  </div>
</div>
