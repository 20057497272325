import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ApiData } from '../../../Library/api-data/api-data.model';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ApiError } from '../../../Library/api-data/api-error.model';
import { environment } from '../../../../environments/environment';
import { Verification } from '../models/verification.model';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {

  url: string = environment.apiUrl + '/verifications';

  constructor(private httpClient: HttpClient) {
  }

  verify(data?: {}): Observable<Verification> {
    return this.httpClient.post<ApiData<Verification>>(`${this.url}/verify`, data).pipe(
      catchError((error) => {
        error = new ApiError(error.error);
        return throwError(error);
      }),
      map((response: ApiData<Verification>) => {
        return response.data;
      })
    );
  }
}
