import {DOCUMENT, isPlatformBrowser, isPlatformServer} from '@angular/common';
import {Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2} from '@angular/core';

type HTMLAttribute = {
  name: string,
  value: string
};

@Injectable({
  providedIn: 'root'
})
export class Platform {

  renderer: Renderer2;
  loadedScripts: string[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  isServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  appendScriptToBody(url: string, isAsync: boolean = false, additionalAttributes: HTMLAttribute[] = []): Promise<boolean> {

    return new Promise((resolve, reject) => {

      if (this.loadedScripts.includes(url)) {
        resolve(true);
        return;
      }

      const scriptElement: HTMLScriptElement = this.renderer.createElement('script');
      scriptElement.src = url;
      if (isAsync) {
        scriptElement.async = true;
      }

      if (additionalAttributes.length) {
        additionalAttributes.forEach(attribute => {
          scriptElement.setAttribute(attribute.name, attribute.value);
        });
      }

      scriptElement.onload = () => {
        this.loadedScripts.push(url);
        resolve(true);
      };
      scriptElement.onerror = (error: any) => resolve(false);

      this.renderer.appendChild(this.document.body, scriptElement);

    });

  }

}
