import { Injectable } from '@angular/core';
import { Bill } from '../models/bill.model';
import { Observable, Observer } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class BillService {

    bill: Observable<Bill>;

    constructor(private activatedRoute: ActivatedRoute) {
        this.bill = new Observable<Bill>((observer: Observer<Bill>) => {
            this.activatedRoute.queryParams.subscribe(params => {
                if (this.billParamsValid(params)) {
                    observer.next(new Bill(params));
                }
            });
        });
    }

    public billParamsValid(params: any): boolean {
        return params &&
            params.klijent &&
            params.email &&
            params.poziv_na_broj &&
            params.dug &&
            params.racun &&
            params.model &&
            params.racun_id;
    }

}
