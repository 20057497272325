import { Injectable } from '@angular/core';
import { BillingAddress } from '../models/billing-address.model';

@Injectable({
    providedIn: 'root'
})
export class BillingAddressService {

    constructor() {
    }

    public save(address: BillingAddress): void {
        localStorage.setItem('com.epcg.placanja:billing_data', JSON.stringify(address));
    }

    public forget(): void {
        localStorage.removeItem('com.epcg.placanja:billing_data');
    }

    public getSavedAddress(): BillingAddress {
        const storageItem = localStorage.getItem('com.epcg.placanja:billing_data');
        if (!storageItem) {
            return null;
        }

        const object = JSON.parse(storageItem);
        return new BillingAddress(object);
    }
}
