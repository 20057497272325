import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingInfoComponent } from './components/billing-info/billing-info.component';



@NgModule({
    declarations: [
        BillingInfoComponent
    ],
    exports: [
        BillingInfoComponent
    ],
    imports: [
        CommonModule
    ]
})
export class BillingInfoModule { }
