import { AfterViewInit, Component, Inject, LOCALE_ID, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from '../../@shared/services/seo/seo.service';

@Component({
  selector: 'app-base',
  template: '',
})
export class BaseComponent implements OnDestroy, AfterViewInit {

  ngUnsubscribe = new Subject();

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected seoService: SeoService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) public platformId,
  ) {
  }

  ngAfterViewInit(): void {
    this.seoService.refreshForRoute(this.activatedRoute.snapshot);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
