import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';

export class BillingAddress {

    first_name?: string;
    last_name?: string;
    address?: string;
    city?: string;
    country?: string;

    public constructor(data?: any) {
        this.first_name = data?.first_name;
        this.last_name = data?.last_name;
        this.address = data?.address;
        this.city = data?.city;
        this.country = data?.country;
    }
}

@Injectable({
    providedIn: 'root'
})
export class BillingAddressAdapter extends BaseAdapter<BillingAddress> {
    constructor() {
        super(BillingAddress);
    }

    adapt(data: any): BillingAddress {
        return super.adapt(data);
    }

}
