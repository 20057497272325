import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { SeoItem } from './seo-item.model';

@Injectable({
  providedIn: 'root'
})
export class SeoOgService {

  private defaultText: string = '';
  private currentObject: any = null;
  private seoItem: SeoItem = null;

  constructor(
    private metaService: Meta
  ) {
  }

  setDefaultText(defaultText: string): void {
    this.defaultText = defaultText;
  }

  setCurrentObject(currentObject): void {
    this.currentObject = currentObject;
  }

  setSeoItem(seoItem: SeoItem): void {
    this.seoItem = seoItem;
  }

  reset(): void {
    this.metaService.removeTag('property="og:title"');
    this.metaService.removeTag('property="og:description"');
    this.metaService.removeTag('property="og:type"');
    this.metaService.removeTag('property="og:image"');
    this.metaService.removeTag('property="og:image:alt"');
    this.metaService.removeTag('property="og:url"');
  }

  refresh(seoItem: SeoItem): void {
    const title = seoItem.seo_fb_title ? seoItem.seo_fb_title : seoItem.getTitle();
    const description = seoItem.seo_fb_description ? seoItem.seo_fb_description : seoItem.getDescription();
    const type = seoItem.seo_fb_type ? seoItem.seo_fb_type : 'website';

    this.metaService.updateTag({property: 'og:title', content: title});
    this.metaService.updateTag({property: 'og:description', content: description});
    this.metaService.updateTag({property: 'og:type', content: type});
    this.metaService.updateTag({property: 'og:url', content: seoItem.url});

    if (seoItem.seo_fb_image_url) {
      this.metaService.updateTag({property: 'og:image', content: seoItem.seo_fb_image_url});
      this.metaService.updateTag({property: 'og:image:alt', content: seoItem.seo_fb_title});
    }
  }
}
