<div class="w-full max-w-max ">
	<div class="w-full relative">
		<div class="container mb-17">
			<div class="w-full md:w-14/24 md:ml-5/24">
				<div class="flex flex-col gap-10">
					<div class="flex flex-col gap-6">
						<h5 class="text-18 leading-122 font-700">
							Osnovne odredbe
						</h5>
						<p>
							Plaćanje računa putem web linka važi samo za kupce iz grupe potrošnje "Domaćinstva".
						</p>
						<p>
							Servis je dostupan svim domaćinstvima, koja imaju aktiviran servis "Elektronski račun".
						</p>
						<p>
							Za plaćanje obaveza po osnovu utrošene električne energije putem ovog servisa kupcima se ne naplaćuje provizija.
						</p>
						<p>
							Na stranici za plaćanje automatski se generiše poziv na broj, koji se, kao i ukupan iznos za uplatu, preuzima sa računa za koji je link za plaćanje poslat putem mejla kreiran.
						</p>
						<p>
							Kupac u polju "želim da uplatim" unosi željeni iznos uplate. U tom polju automatski se pojavljuje podatak o ukupnom iznosu, a kupac može da promijeni iznos uplate.
						</p>
					</div>

					<div class="flex flex-col gap-6">
						<h5 class="text-18 leading-122 font-700">
							Uslovi korišćenja
						</h5>
						<p>
							Podaci sa linka za plaćanje mjesečnog računa za potrošnju električne energije registrovanih potrošača EPCG se ne smiju reprodukovati, distribuirati ili koristiti u bilo koje svrhe, osim za plaćanje navedenog računa, bez izričitog pristanka EPCG i registrovanog potrošača, i u suprotnom će se smatrati zloupotrebom.
						</p>
						<p>
							Korišćenjem usluge online plaćanja mjesečnog računa za potrošnju električne energije, potrošač se obavezuje da će ovu opciju plaćanja koristiti isključivo u tu svrhu i na sopstvenu odgovornost.
						</p>
						<p>
							EPCG se obavezuje da će link za plaćanje mjesečnog računa dostavljati isključivo registrovanom potrošaču, koji je prethodno odabrao uslugu elektronskog dostavljanja računa, a na njegovu/njenu email adresu, koju je u Zahtjevu za navedenu uslugu dostavio.
						</p>
						<p>
							Prihvatanjem ovih Opštih uslova na Internet stranici, potvrđujete da ste ih pročitali, razumjeli i da pristajete na obavezujuću primjenu Opštih uslova prilikom uplate.
						</p>
					</div>

					<div class="flex flex-col gap-6">
						<h5 class="text-18 leading-122 font-700">
							Politika privatnosti
						</h5>
						<p>
							Ova Politika privatnosti reguliše praksu prikupljanja informacija od korisnika prilikom korišćenja internet stranice https://plaćanja.epcg.com. Sigurnost i bezbjednost podataka potrošača prilikom plaćanja mjesečnog računa garantuje procesor platnih kartica. Elektroprivreda Crne Gore A.D. Nikšić nema uvid niti pristup podacima platnih kartica korisnika koji se odluče za opciju elektronskog plaćanja i samim tim nema odgovornosti, niti posebnih zahtjeva za njihovo arhiviranje.
						</p>
						<p>
							Zadržavamo diskreciono pravo da ovu Politiku privatnosti možemo izmijeniti u svako doba i da tako izmijenjen tekst postane važeći odmah po objavi istog na Web sajtu.
						</p>
						<p>
							Nastavak Vašeg korišćenja Web sajta nakon izmjena, podrazumijeva da prihvatate sve uslove tako izmijenjene Politike privatnosti. Stoga savjetujemo korisnike da periodično ponovo detaljno pročitaju informacije sadržane u Politici privatnosti, kako bi bili informisani o eventualnim promjenama.
						</p>
					</div>

					<div class="flex flex-col gap-6">
						<h5 class="text-18 leading-122 font-700">
							Načini plaćanja
						</h5>
						<p>
							Plaćanje mjesečnih računa na našoj internet stranici je moguće izvršiti na jedan od sljedećih načina: platnim karticama - VISA, Maestro ili MasterCard koje podržavaju plaćanje preko Interneta. Plaćanje karticama je realizovano u saradnji sa AllSecure doo i Hipotekarnom Bankom i obavlja se na bezbjedan i sertifikovan način preko AllSecure Paymet Gateway-a, jednostavnim unosom podataka sa platne kartice.
						</p>
						<p>
							Nakon što se unesu podaci o kartici i potvrdi plaćanje, iznos će biti skinut sa Vašeg računa.
						</p>
						<p>
							Provjerite kod banke koja je karticu izdala da li Vaša kartica podržava plaćanje preko Interneta.
						</p>
					</div>

					<div class="flex flex-col gap-6">
						<h5 class="text-18 leading-122 font-700">
							Zaštita podataka i bezbjednost prilikom plaćanja
						</h5>
						<p>
							Prilikom unošenja podataka o platnoj kartici, povjerljive informacije se prenose putem javne mreže u zaštićenoj (kriptovanoj) formi upotrebom SSL protokola, primjenom najsavremenijih metoda tokenizacije osjetljivih podataka, i u skladu sa PCI-DSS standardima. Niti jednog trenutka podaci o platnoj kartici nijesu dostupni EPCG.
						</p>
						<p>
							3D Secure zaštita za sve trgovce i kupce - AllSecure Payment Gateway koristi najviše globalne standarde zaštite i privatnosti podataka. Svi trgovci koji koriste AllSecure Payment Gateway su automatski uključeni u 3D-Secure zaštitu, čime se kupcima garantuje bezbjednost kupovine. Brojevi platnih kartica kupaca se ne čuvaju na sistemu trgovca a sami upis se štiti SSL enkripcijom podataka.
						</p>
						<p>
							PCI DSS Standardi - AllSecure Payment Gateway se konstantno usaglašava sa svim zahtjevima kartičarskih organizacija u cilju povećanja nivoa bezbjednosti trgovaca i kupaca. Od 2005 godine do danas, bez prekida, sistem je sertifikovan kao PCI-DSS Level 1 što predstavlja najviši standard u industriji. PCI Data Security Standard (PCI-DSS) je norma koja definiše neophodne mjere bezbjednosti pri obradi, čuvanju i prenošenju osjetljivih kartičarskih podataka. PCI Standardima se štite osjetljivi podaci o korisniku kartice tokom cijelog procesa plaćanja: od momenta unosa podataka na prodajnom mjestu trgovca, tokom komunikacija između trgovca i relevantnih banaka i kartičarskih organizacija, kao i kasnije čuvanje tih podataka.
						</p>
					</div>

					<div class="flex flex-col gap-6">
						<h5 class="text-18 leading-122 font-700">
							Knjiženje uplata
						</h5>
						<p>
							U skladu sa članom 15. Dodatnih uslova za snabdijevanje električnom energijom broj 10-00-10055 od 28.02.2020. godine, ukoliko je prilikom plaćanja računa u polju "poziv na broj" precizno naznačen poziv na broj po modulu sa računa za koji kupac želi da izmiri obaveze po osnovu utrošene električne energije, EPCG će tom uplatom zatvoriti obaveze po predmetnom računu na koji se poziv na broj po modulu odnosi.
						</p>
						<p>
							Potvrda o uspješnosti transakcije je potvrda da je kupac koristio servis online plaćanja računa. EPCG će proknjižiti uplatu izvršenu putem ovog servisa tek kada banka izvrši prenos sredstava na žiro račun EPCG.
						</p>
						<p>
							Kada EPCG proknjiži uplatu na osnovu odgovarajućih podataka o pozivu na broj i ukoliko banka ne ospori transakciju, smatra se da je kupac u iznosu realizovane transakcije izmirio obaveze prema EPCG.
						</p>
					</div>

					<div class="flex flex-col gap-6">
						<h5 class="text-18 leading-122 font-700">
							Postupak reklamacije i povraćaj sredstava
						</h5>
						<p>
							Kupac je u obavezi da provjeri podatke koji se automatski generišu u polju "Broj računa" prilikom plaćanja računa na portalu https://placanja.epcg.com. Ukoliko primijeti nepravilnost prilikom automatskog generisanja podataka kupac može da se javi podršci mejlom na
							<a href="mailto:racun@epcg.com" class="text-brand-400 underline">racun@epcg.com</a>.
						</p>
						<p>
							Kupac je isključivo odgovoran za posljedice nastale usljed netačnog popunjavanja polja "želim da uplatim".
						</p>
						<p>
							U slučaju pogrešne uplate kupac se obraća banci koja je izdala platnu karticu sa kojom je izvršio transakciju. EPCG je dužna da izvrši povraćaj iznosa osporene transakcije banci na njen zahtjev, nakon čega će ponovo zadužiti kupca za iznos koji je banci nadoknađen. Povraćaj sredstava se vrši isključivo preko iste VISA, Maestro ili MasterCard kartice koja je korištena za plaćanje.
						</p>
						<p>
							U slučaju tehničkih problema sa servisom kupac može da se javi podršci, mejlom na
							<a href="mailto:web.portal@epcg.com" class="text-brand-400 underline">
								web.portal@epcg.com
							</a>.
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="w-full border-t border-gray-200">
			<div class="container">
				<app-billing-info></app-billing-info>
			</div>
		</div>

	</div>
</div>
