import { Component, Input, OnInit } from '@angular/core';
import { Bill } from '../../../bill/models/bill.model';

@Component({
  selector: 'app-homepage-bill-amount-and-due-date',
  templateUrl: './homepage-bill-amount-and-due-date.component.html'
})
export class HomepageBillAmountAndDueDateComponent implements OnInit {

  @Input() bill: Bill;
  formattedAmount: string;

  ngOnInit(): void {
      this.formattedAmount = (this.bill.amount / 100).toLocaleString('fr');
  }

}
