export class Resource<T> {
  public title?: string;
  public description?: string;
  public slug?: string = '';
  public redirect?: boolean = false;
  public statusCode?: number;
  public redirectUrl?: string;

  public static formatDate(value): Date {
    const dateTime = value.split(' ');
    const date = dateTime[0].split('-');
    const time = dateTime[1].split(':');
    return new Date(date[0], date[1] - 1, date[2], time[0], time[1]);
  }

  public constructor(data?: {}) {
    Object.assign(this, data);
  }

  public getSlug(): string {
    return this.slug;
  }

  public getSeoTitle(): string {
    return '';
  }

  public getSeoDescription(): string {
    return '';
  }

  public getSeoKeywords(): string {
    return '';
  }

  public getSeoImage(): string {
    return '';
  }

  protected excerpt(input: string, length: number = 320): string {
    if (!input) {
      return '';
    }

    // Replace new lines with space and strip tags
    let result = input.replace(/<\/p><p>/g, ' ');
    result = result.replace(/(<([^>]+)>)/ig, ' ');

    if (result.length > length) {
      result = result.substring(0, length);
      let lastIndex = result.lastIndexOf(' ');
      result = result.substring(0, lastIndex);

      let newLastIndex = result.lastIndexOf(' ');
      let lastWord = result.substring(newLastIndex, lastIndex);

      // Removes every word which length is smaller then 3 characters
      while (lastWord.length < 4) {
        lastIndex = newLastIndex;
        result = result.substring(0, lastIndex);
        newLastIndex = result.lastIndexOf(' ');
        lastWord = result.substring(newLastIndex, lastIndex);
      }

      // Removes dots and comma
      if (result.charAt(result.length - 1) === '.' || result.charAt(result.length - 1) === ',') {
        result = result.slice(0, -1);
      }

      result = result.substring(0, lastIndex) + '…';
    }
    return result;
  }

}
