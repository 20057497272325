<div *ngIf="!accountVerified" class="w-full bg-brand-100 border border-gray-200 rounded-[10px] px-5 py-5 flex flex-col">
  <span class="text-16 leading-122 font-700 text-gray-700">
    Popuni iz sačuvanog
  </span>
  <span class="mt-2 text-16 leading-137 text-gray-400">
    Ako ste ranije sačuvali vaše podatke za plaćanje, potvrdite vašu email adresu kako bi se oni popunili.
  </span>
  <button type="button"
          (click)="openAccountModal()"
          class="mt-5 w-full btn-secondary">
    Popuni
  </button>
</div>

<div *ngIf="accountVerified && accountHasData"
     class="w-full bg-status-green-100 border border-status-green-400 rounded-[10px] px-4 sm:px-9 py-4 flex flex-row items-center gap-4">
  <div class="flex sm:hidden md:flex items-center justify-center shrink-0 bg-status-green-400 w-7 h-7 rounded-full">
    <fa-icon [icon]="faCheck" class="text-14 text-white"></fa-icon>
  </div>
  <span class="text-16 sm:text-12 md:text-16 leading-137 text-gray-700">
    Email adresa
    <span class="font-700">{{ verification?.account.email }}</span>
    je validna i pomoću nje su sačuvani sljedeći podaci.
  </span>
</div>

<div *ngIf="accountVerified && !accountHasData"
     class="w-full bg-gray-200 border border-gray-200 rounded-[10px] px-4 sm:px-9 py-4 flex flex-row items-start gap-4">
  <div class="flex sm:hidden md:flex items-center justify-center shrink-0 bg-status-green-400 w-7 h-7 rounded-full">
    <fa-icon [icon]="faCheck" class="text-14 text-white"></fa-icon>
  </div>
  <span class="text-16 sm:text-12 md:text-16 leading-137 text-gray-700">
    Email adresa
    <span class="font-700">{{ verification?.account.email }}</span>
    je validna, ali uz nju nemate sačuvane podatke.
    <br/>
    <br/>
    Potrebno je da nakon što ispunite podatke, potvrdite njihovo čuvanje kako bi oni bili sačuvani za naredno plaćanje.
  </span>
</div>

<div *ngIf="accountModalOpen">
  <app-account-modal [accountEmail]="clientEmail"
                     (canceled)="accountModalCanceled()"
                     (accountVerified)="onAccountVerified($event)">
  </app-account-modal>
</div>
