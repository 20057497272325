<footer class="w-full max-w-max">
	<div class="w-full py-5 border-t border-gray-300">
		<div class="container flex flex-col md:flex-row items-start md:items-center justify-between gap-y-4">
			<div class="flex flex-col md:flex-row gap-[14px] text-brand-400 text-16 leading-122">
				<a [routerLink]="[faqRoute]" class="md:border-r md:border-tory md:pr-[14px] hover:underline">
					Česta pitanja
				</a>
				<a [routerLink]="termsRoute" class="hover:underline">
					Uslovi korišćenja i politika privatnosti
				</a>
			</div>
			<div class="flex flex-col md:flex-row gap-[6px]">
				<img src="assets/img/logos/cards-allsecure.png" alt="AllSecure" width="233" height="46">
				<img src="assets/img/logos/cards-hipotekarna.png" alt="Hipotekarna Banka" width="247" height="46">
			</div>
		</div>
	</div>
	<div class="w-full bg-tory py-2">
		<div class="container flex flex-col md:flex-row items-center md:justify-end md:gap-3 text-white text-12 leading-122">
			<span class="md:border-r md:border-white md:pr-3">
				© EPCG 2023.
				<span>Sva prava zadržana.</span>
			</span>
			<span class="font-bold">
				Made with
				<fa-icon [icon]="faHeart"></fa-icon>
				by
				<a href="https://www.fleka.me" target="_blank" class="hover:underline">fleka</a>.
			</span>
		</div>
	</div>
</footer>
