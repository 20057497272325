import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Card } from '../../card/models/card.model';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { CardService } from '../../card/services/card.service';

@Component({
  selector: 'app-homepage-card-selector',
  templateUrl: './homepage-card-selector.component.html',
  styleUrls: ['homepage-card-selector.component.scss']
})
export class HomepageCardSelectorComponent implements OnInit {

  @Input() card: Card;
  @Input() verification_uuid: string;

  @Output() cardSelectorChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cardDetached: EventEmitter<void> = new EventEmitter<void>();

  detachCardModalVisible: boolean = false;

  faTrashCan = faTrashCan;

  constructor(private cardService: CardService) {
  }

  ngOnInit(): void {
    this.cardSelectorChanged.emit(true);
  }

  handleSavedCard(): void {
    this.cardSelectorChanged.emit(true);
  }

  handleOther(): void {
    this.cardSelectorChanged.emit(false);
  }

  openDetachCardModal(): void {
    if (!this.verification_uuid || !this.card) {
      return;
    }

    this.detachCardModalVisible = true;
  }

  detachCardModalCanceled(): void {
    this.detachCardModalVisible = false;
  }

  onCardDetached(): void {
    this.detachCardModalVisible = false;
    this.card = null;
    this.cardDetached.emit();
  }
}
