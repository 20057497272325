<div class="fixed top-0 left-0 z-10 w-screen h-screen bg-black/60">
  <div class="container">
    <div class="w-full sm:w-12/24 sm:ml-6/24 pt-24 sm:pt-40">
      <div class="w-full bg-white rounded-3 px-7 md:px-28 py-6 md:py-11">
        <div class="w-full rounded-2 border border-gray-300 p-6">
          <div class="flex items-center gap-3">
            <div *ngIf="card.card_brand === 'MASTERCARD'" class="flex items-center">
              <img src="assets/img/logos/mastercard.png" width="110" height="64" class="w-12 sm:w-18 h-auto" alt="mastercard"/>
            </div>
            <div *ngIf="card.card_brand === 'VISA'" class="flex items-center">
              <img src="assets/img/logos/visa.png" width="111" height="64" class="w-12 sm:w-18 h-auto" alt="mastercard"/>
            </div>
            <span class="text-18 leading-122 text-gray-900 font-700">
              **** {{ card.last_four_digits }}
            </span>
          </div>
          <div class="w-full mt-4 flex flex-col md:flex-row gap-y-1 text-14 leading-122 text-gray-900 font-500">
            <span>
              {{ card.card_holder }}
            </span>
            <span class="md:flex-1 md:text-right">
              {{ card.expiry_month }} / {{ card.expiry_year }}
            </span>
          </div>
        </div>
        <div class="w-full mt-5 sm:mt-7 flex flex-col items-center gap-3">
          <span class="text-18 sm:text-24 text-gray-700 font-700 text-center">
            Da li želite da obrišete karticu?
          </span>
          <span class="block md:px-7 text-center text-16 leading-137 text-gray-400">
            Ukoliko obrišete karticu ona neće biti više sačuvana za naredna plaćanja.
          </span>
        </div>

        <button
            type="button"
            class="mt-4 sm:mt-7 md:px-8 w-full btn-primary"
            (click)="detachCard()"
            [disabled]="loading">
          Potvrdi
        </button>

        <div class="w-full text-center mt-5 sm:mt-6">
          <button type="button"
                  (click)="cancel()"
                  [disabled]="loading"
                  class="mx-auto text-18 leading-122 text-brand-400">
            Odustani
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
