<div class="w-full max-w-max ">
	<div class="w-full relative">
		<div class="container mb-17">
			<div class="w-full md:w-14/24 md:ml-5/24">
				<h1 class="block mb-7 text-28 sm:text-36 leading-122 font-700">
					Najčešća pitanja
				</h1>
				<div class="flex flex-col gap-8 text-16 leading-122">
					<div class="flex flex-col gap-2">
						<h5 class="font-700">
							Ko može plaćati mjesečne račune online putem?
						</h5>
						<p>
							Svaki potrošač koji je odabrao uslugu elektronskog dostavljanja računa može platiti račun online, putem linka koji će biti naveden u emailu.
						</p>
					</div>

					<div class="flex flex-col gap-2">
						<h5 class="font-700">
							Kako se vrši plaćanje mjesečnog računa za električnu energiju?
						</h5>
						<p>
							Odabirom linka iz emaila, otvoriće Vam se stranica na kojoj ćete moći da platite Vaš mjesečni račun za potrošnju električne energije. Kako biste izvršili uplatu, potrebno je da unesete iznos koji želite platiti i zatim da u sljedećem koraku unesete podatke Vaše platne kartice i potvrdite uplatu.
						</p>
					</div>

					<div class="flex flex-col gap-2">
						<h5 class="font-700">
							Kako znam da je račun plaćen?
						</h5>
						<p>
							Stići će Vam SMS poruka od Vaše banke sa iskazanim iznosom koji ste platili prema Elektrodistribuciji. To je Vaša potvrda plaćanja mjesečnog računa online putem.
						</p>
					</div>

					<div class="flex flex-col gap-2">
						<h5 class="font-700">
							Da li na linku za online plaćanje mogu da vidim ažurirano stanje nakon izvršene uplate?
						</h5>
						<p>
							Ne možete - link koji Vam stiže u emailu je Vaš račun za navedeni mjesec, i uvijek će iskazivati isti iznos. To je samo elektronska forma računa koji Vam EPCG izdaje.
						</p>
					</div>

					<div class="flex flex-col gap-2">
						<h5 class="font-700">
							Kako znam da je ovaj način plaćanja siguran?
						</h5>
						<p>
							Procesor platnih kartica garantuje sigurnost i zaštitu Vaših podataka.
						</p>
					</div>

					<div class="flex flex-col gap-2">
						<h5 class="font-700">
							Kojim karticama mogu vršiti plaćanje mjesečnog računa online?
						</h5>
						<p>
							Plaćanje računa online možete obaviti debitnim i kreditnim Visa i MasterCard karticama.
						</p>
					</div>

					<div class="flex flex-col gap-2">
						<h5 class="font-700">
							Da li imam dodatnih troškova za ovu transakciju?
						</h5>
						<p>
							Nemate dodatnih troškova prilikom plaćanja računa online putem. Sa Vaše kartice će biti skinut iznos koji ste Vi unijeli da želite platiti.
						</p>
					</div>

					<div class="flex flex-col gap-2">
						<h5 class="font-700">
							Da li mogu da poništim transakciju?
						</h5>
						<p>
							Transakciju ne možete poništiti online, već je potrebno da kontaktirate Vašu banku.
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="w-full border-t border-gray-200">
			<div class="container">
				<app-billing-info></app-billing-info>
			</div>
		</div>

	</div>
</div>
