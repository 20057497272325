import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Adapter } from '../adapter/adapter';
import { ApiData } from '../api-data/api-data.model';
import { ApiError } from '../api-data/api-error.model';

export class ResourceService<T> {

  constructor(protected url: string, protected httpClient: HttpClient, protected adapter: Adapter<T>) {
    this.url = environment.apiUrl + url;
  }

  all(data?: {}): Observable<ApiData<T[]>> {
    const params = this.prepareParams(data);

    return this.httpClient.get<ApiData<T[]>>(this.url, {params}).pipe(
      catchError((error) => {
        error = new ApiError(error.error);
        return throwError(error);
      }),
      map((response: any) => {
        if (!response.data.data) {
          return response;
        }
        response.data = this.adapter.adaptArray(response);
        return new ApiData<T[]>(response);
      }),
    );
  }

  get(id: string, data?: {}): Observable<T> {
    const params = this.prepareParams(data);

    return this.httpClient.get<T>(`${this.url}/${id}`, {params}).pipe(
      catchError((response) => {
        const error = new ApiError(response.error);
        return throwError(error);
      }),
      map((response: any) => {
        return this.adapter.adapt(response);
      }),
    );
  }

  getBySlug(slug: string, data?: {}): Observable<T> {
    const params = this.prepareParams(data);

    return this.httpClient.get<T>(`${this.url}/${slug}`, {params}).pipe(
      catchError((response) => {
        const error = new ApiError(response.error);
        return throwError(error);
      }),
      map((response: any) => {
        return this.adapter.adapt(response);
      }),
    );
  }

  getWithToken(id: string, data: {}, token): Observable<T> {
    const params = this.prepareParams(data);

    return this.httpClient.get<T>(`${this.url}/${id}/preview`, {
      params,
      headers: {
        Authorization: token,
      },
    }).pipe(
      catchError((response) => {
        const error = new ApiError(response.error);
        return throwError(error);
      }),
      map((response: any) => {
        return this.adapter.adapt(response);
      }),
    );
  }

  save(data: any, id?: number): Observable<T> {
    const params = data;

    if (id === undefined) {
      return this.httpClient.post<T>(this.url, params).pipe(
        catchError((response) => {
          const error = new ApiError(response.error);
          return throwError(error);
        }),
        map((response) => {
          return this.adapter.adapt(response);
        }),
      );
    } else {
      return this.httpClient.put<T>(`${this.url}/${id}`, params).pipe(
        catchError((response) => {
          const error = new ApiError(response.error);
          return throwError(error);
        }),
        map((response) => {
          return this.adapter.adapt(response);
        }),
      );
    }
  }

  delete(id: number): Observable<T> {
    return this.httpClient.delete(`${this.url}/${id}`).pipe(
      catchError((response) => {
        const error = new ApiError(response.error);
        return throwError(error);
      }),
      map((response) => {
        return this.adapter.adapt(response);
      }),
    );
  }

  prepareParams(data?: {}): HttpParams {
    let params = new HttpParams();

    if (!data) {
      return params;
    }

    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        params = params.set(property, data[property]);
      }
    }

    return params;
  }

}
