import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CountdownService {

  private elapsedSeconds: number;
  elapsedSecondsSubject: Subject<number> = new Subject<number>();

  timeout: any;

  emailVerified: boolean = false;
  verificationUuid: string;

  constructor(@Inject(PLATFORM_ID) private platformId) {
  }

  countdown(verificationUuid: string): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (this.timeout) {
      return;
    }

    if (!this.elapsedSeconds) {
      this.elapsedSeconds = 60;
    }

    this.emailVerified = true;
    this.verificationUuid = verificationUuid;

    const tick = (): void => {
      this.elapsedSeconds--;

      this.elapsedSecondsSubject.next(this.elapsedSeconds);

      if (this.elapsedSeconds > 0) {
        this.timeout = setTimeout(tick, 1000);
      } else {
        clearTimeout(this.timeout);

        this.timeout = null;
        this.elapsedSeconds = null;
        this.emailVerified = false;
        this.verificationUuid = null;

        this.elapsedSecondsSubject.next(0);
      }
    };

    tick();
  }

}
