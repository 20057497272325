import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Transaction } from '../../../transaction/models/transaction.model';
import { TransactionService } from '../../../transaction/services/transaction.service';
import { ApiError } from '../../../../Library/api-data/api-error.model';
import { faCircleExclamation, faCircleCheck } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-homepage-transaction-status',
  templateUrl: './homepage-transaction-status.component.html',
  styleUrls: ['./homepage-transaction-status.component.scss']
})
export class HomepageTransactionStatusComponent implements OnInit {

  transaction: Transaction;

  successful: boolean = false;
  failed: boolean = false;

  errorsArray = [
    {
      code: 1000,
      message: `Request failed`,
      description: `Some fundamental error in your request`
    },
    {
      code: 1001,
      message: `Invalid response`,
      description: `The upstream system responded with an unknown response`
    },
    {
      code: 1002,
      message: `Invalid request data`,
      description: `Request data are malformed or missing`
    },
    {
      code: 1003,
      message: `Processing error`,
      description: `Transaction could not be processed`
    },
    {
      code: 1004,
      message: `Invalid signature`,
      description: `The request signature you provided was wrong`
    },
    {
      code: 1005,
      message: `Invalid XML`,
      description: `The XML you provided was malformed or invalid`
    },
    {
      code: 1006,
      message: `Logical error`,
      description: `Preconditions failed, e.g. capture on a failed authorize`
    },
    {
      code: 1007,
      message: `Invalid configuration`,
      description: `Something is wrong with your configuration, please contact your integration engineer`
    },
    {
      code: 1008,
      message: `Unexpected system error`,
      description: `Unexpected system error`
    },
    {
      code: 1009,
      message: `Too many requests`,
      description: `Rate limit has been exceeded`
    },
    {
      code: 9999,
      message: `Unknown error`,
      description: `We received an error which is not (yet) mapped to a better error code`
    },
    {
      code: 2001,
      message: `Account closed externally`,
      description: `The customer cancelled permission for his payment instrument externally`
    },
    {
      code: 2002,
      message: `User cancelled`,
      description: `Transaction was cancelled by customer`
    },
    {
      code: 2003,
      message: `Transaction declined`,
      description: `Transaction declined by upstream system/bank`
    },
    {
      code: 2004,
      message: `Quota regulation`,
      description: `Some limit reached`
    },
    {
      code: 2005,
      message: `Transaction expired`,
      description: `Customer took to long to submit his payment info`
    },
    {
      code: 2006,
      message: `Insufficient funds`,
      description: `Card limit reached`
    },
    {
      code: 2007,
      message: `Incorrect payment info`,
      description: `Incorrect payment info`
    },
    {
      code: 2008,
      message: `Invalid card`,
      description: `Card is invalid`
    },
    {
      code: 2009,
      message: `Expired card`,
      description: `Expired card`
    },
    {
      code: 2010,
      message: `Fraudulent card`,
      description: `Fraudulent card`
    },
    {
      code: 2011,
      message: `Unsupported card`,
      description: `Unsupported card`
    },
    {
      code: 2012,
      message: `Transaction cancelled`,
      description: `Transaction cancelled`
    },
    {
      code: 2013,
      message: `Risk check block`,
      description: `Risk check block`
    },
    {
      code: 2014,
      message: `Pickup card`,
      description: `Pickup card`
    },
    {
      code: 2015,
      message: `Lost card`,
      description: `Card is claimed as lost`
    },
    {
      code: 2016,
      message: `Stolen card`,
      description: `Stolen card`
    },
    {
      code: 2017,
      message: `IBAN invalid`,
      description: `IBAN invalid`
    },
    {
      code: 2018,
      message: `BIC invalid`,
      description: `BIC invalid`
    },
    {
      code: 2019,
      message: `Customer data invalid`,
      description: `Customer data invalid`
    },
    {
      code: 2020,
      message: `CVV required`,
      description: `CVV required`
    },
    {
      code: 2021,
      message: `3D-Secure Verification failed`,
      description: `3D-Secure Verification failed`
    },
    {
      code: 2022,
      message: `3D-Secure Soft declined`,
      description: `3D-Secure Soft declined`
    },
    {
      code: 8001,
      message: `Transaction not found`,
      description: `No transaction was found for this specific connector determined by the apiKey`
    },
    {
      code: 7001,
      message: `schedule request is invalid`,
      description: `schedule request is invalid`
    },
    {
      code: 7002,
      message: `schedule request failed`,
      description: `schedule request failed`
    },
    {
      code: 7005,
      message: `scheduleAction is not valid`,
      description: `scheduleAction is not valid`
    },
    {
      code: 7010,
      message: `registrationId is required`,
      description: `registrationId is required`
    },
    {
      code: 7020,
      message: `registrationId is not valid`,
      description: `registrationId is not valid`
    },
    {
      code: 7030,
      message: `reference transaction not a register`,
      description: `The registrationId must point to a register or a debit-with-register or a preauthorize-with-register`
    },
    {
      code: 7035,
      message: `initial transaction is not a register`,
      description: `The transaction for starting a schedule must be a register, a debit-with-register or a preauthorize-with-register`
    },
    {
      code: 7036,
      message: `invalid initial period`,
      description: `The period between the initial and second transaction must be greater than 24 hours`
    },
    {
      code: 7040,
      message: `The scheduleId is not valid or does not match to the connector`,
      description: `The scheduleId is not valid or does not match to the connector`
    },
    {
      code: 7050,
      message: `The startDateTime is invalid or older than 24 hours`,
      description: `The startDateTime is invalid or older than 24 hours`
    },
    {
      code: 7060,
      message: `The continueDateTime is invalid or older than 24 hours`,
      description: `The continueDateTime is invalid or older than 24 hours`
    },
    {
      code: 7070,
      message: `The status of the schedule is not valid for the requested operation`,
      description: `The status of the schedule is not valid for the requested operation`
    },
    {
      code: 3001,
      message: `Timeout`,
      description: `Timeout`
    },
    {
      code: 3002,
      message: `Not Allowed`,
      description: `Not Allowed`
    },
    {
      code: 3003,
      message: `Temporary unavailable`,
      description: `Temporary unavailable`
    },
    {
      code: 3004,
      message: `Duplicate transaction ID`,
      description: `Duplicate transaction ID`
    },
    {
      code: 3005,
      message: `Communication error`,
      description: `Communication error`
    },
    {
      code: 4001,
      message: `Chargeback reverted`,
      description: `Chargeback was reverted`
    },
    {
      code: 4002,
      message: `Payment dispute`,
      description: `A dispute was filed, see the upstream system (e.g. PayPal) for details`
    }
  ];

  firstError: any;

  formattedAmount: string;

  faCircleExclamation = faCircleExclamation;
  faCircleCheck = faCircleCheck;

  constructor(
    private router: ActivatedRoute,
    private transactionService: TransactionService) {
  }

  ngOnInit(): void {
    this.router.queryParams.subscribe(params => {
      if (!params.transaction_code) {
        return;
      }

      const transactionCode = params.transaction_code;

      this.transactionService.get(transactionCode)
        .subscribe((transaction) => {
            this.transaction = transaction;

            this.formattedAmount = (transaction.amount / 100).toLocaleString('fr');

            if (this.transaction.status === 'SUCCESSFUL') {
              this.successful = true;
            } else if (this.transaction.status === 'FAILED') {
              this.failed = true;
            }

            if (this.transaction.errors?.length > 0) {
              this.firstError = this.errorsArray.find(
                err => err.code.toString() === this.transaction.errors[0].code
              );
            }
          },
          (apiError: ApiError) => {
          });

    });
  }
}
