<div class="w-full max-w-max ">
	<div class="w-full relative">
		<div class="container mb-17">
			<div class="w-full md:w-14/24 md:ml-5/24">
				<h1 class="block mb-7 text-28 sm:text-36 leading-122 font-700 sm:pr-6/24">
					Potvrda sigurnosnog statusa web lokacije
				</h1>
				<span class="block mb-8 text-16 leading-122">
					Radi sigurnosti Vašeg plaćanja putem Interneta, molimo Vas, provjerite da ste na stranici Elektroprivreda Crne Gore - AD Nikšić. Potvrda sigurnosnog statusa web lokacije garantuje zaštitu Vaših podataka od mogućih zloupotreba.
				</span>
				<img src="{{ browser_photo }}" width="394" height="139" title="Potvrda sigurnosnog statuta" alt="provjerite sigurnosni statut"/>
			</div>
		</div>

		<div class="w-full border-t border-gray-200">
			<div class="container">
				<app-billing-info></app-billing-info>
			</div>
		</div>

	</div>
</div>
