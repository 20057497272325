export class ApiData<T> {
  data?: T;
  status_code: number;
  message: string;
  totalCount?: number;
  aggs?: {
    [name: string]: {
      doc_count_error_upper_bound: number,
      sum_other_doc_count: number,
      buckets: {
        key: string | number,
        doc_count: number
      }[]
    }
  };

  public constructor(data?: {}) {
    Object.assign(this, data);
  }

}
