import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HomepageComponent } from './components/homepage/homepage.component';
import { RouteName } from '../../Library/routes';

const routes = [
  {
    path: '',
    component: HomepageComponent,
    data: {
      routeName: RouteName.HOME
    }
  }

];
@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ]
})
export class HomepageRoutingModule { }
