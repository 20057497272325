import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { currentLocale, routes as routesTrans } from '../../../Library/routes';
import { faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})

export class HeaderComponent implements OnInit {
    safetyRoute: string = `/${routesTrans[currentLocale].safety}`;

    onHomepage: boolean = null;

    faLockKeyhole = faLockKeyhole;
    faChevronLeft = faChevronLeft;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private router: Router
    ) {
        this.router.events.subscribe(events => {
            const currentRoute = this.router.url.split('?')[0];
            this.onHomepage = currentRoute === '/';
        });
    }

    ngOnInit(): void {
    }

    goBack(): void {
        if (isPlatformBrowser(this.platformId)) {
            history.back();
        }
    }
}
