<div class="w-full max-w-max">
  <div class="w-full relative">
    <div class="container mb-6">
      <div class="w-full sm:w-12/24 text-20 sm:text-34 leading-122 tracking-tighter">
        <h1 *ngIf="!bill">
          <app-homepage-transaction-status></app-homepage-transaction-status>
        </h1>
        <h1 *ngIf="bill">
					<span>
						Vaš račun za električnu energiju za:
					</span>
          <br/>
          <span class="font-700">
						{{ bill?.billing_month }}
					</span>
        </h1>
      </div>
    </div>
    <div *ngIf="bill" class="container mb-8 sm:mb-14">
      <div class="w-full sm:w-12/24">
        <div class="w-full rounded-2 bg-gray-100 sm:border-2 border-gray-300 py-7 px-4 flex flex-col gap-5">
          <h2 class="text-16 sm:text-20 leading-122 font-700">
            {{ bill?.client_name }}
          </h2>
          <div class="flex flex-col gap-1 text-16 leading-122">
            <span class="text-gray-400">Email:</span>
            <span class="text-16 sm:text-20">{{ bill?.client_email }}</span>
          </div>
          <div class="flex flex-col gap-1 text-16 leading-122">
            <span class="text-gray-400">Broj računa:</span>
            <span class="text-16 sm:text-20">{{ bill?.client_account }}</span>
          </div>
        </div>

        <div class="mt-4 sm:mt-5">
          <app-homepage-bill-amount-and-due-date [bill]="bill"></app-homepage-bill-amount-and-due-date>
        </div>

        <div *ngIf="!isMobileLayout" class="pt-22">
          <app-homepage-call-center></app-homepage-call-center>
        </div>
      </div>
    </div>

    <div class="block w-full mb-6 sm:absolute sm:inset-0 sm:pointer-events-none sm:mb-0">
      <div class="sm:container">
        <div *ngIf="bill"
             class="w-full sm:w-11/24 sm:ml-auto sm:rounded-2 sm:py-11 sm:bg-white sm:shadow flex flex-col sm:pointer-events-auto">
          <app-homepage-payment-form></app-homepage-payment-form>
        </div>
        <div *ngIf="!bill" class="hidden sm:block w-11/24 md:w-7/24 ml-13/24 md:ml-14/24">
          <app-homepage-call-center></app-homepage-call-center>
        </div>
      </div>
    </div>

    <div class="w-full bg-brand-100 py-6 mb-17">
      <div class="container">
        <div class="w-full sm:w-12/24 sm:pr-1/24 flex flex-col gap-[14px]">
          <h4 class="text-16 leading-122 font-700">Poštovani/a,</h4>
          <p class="text-14 leading-122">Ukoliko svoj dug izmirujete po osnovu potpisanog sporazuma, zahvaljujemo i molimo Vas da obratite pažnju na redovnost, kako bi sporazum ostao na snazi. Ako imate
            dug, a nemate definisan
            način i dinamiku izmirenja, pozivamo Vas da posjetite lokalno Snabdijevanje i sklopite sporazum, kako biste izbjegli neugodnosti uslijed obustave snabdijevanja električnom energijom.
          </p>
          <p class="text-14 leading-122">
            Podsjećamo Vas da na našem portalu
            <a href="https://www.epcg.com" target="_blank" class="text-brand-400 underline hover:no-underline">
              www.epcg.com,
            </a>
            <a href="https://www.facebook.com/ElektroprivredaCG/" class="text-brand-400 underline hover:no-underline" target="_blank">
              Facebook strani
            </a>
            ili besplatnim pozivom na broj
            <a href="tel:19100" title="Pozovite nas">
              19100
            </a>
            možete saznati više o našim akcijama i aktuelnim promocijama.
          </p>
          <span class="text-16 leading-122 font-700">Vaša EPCG</span>
        </div>
      </div>
    </div>
    <div class="container mb-4 sm:mb-15">
      <div class="w-full sm:w-12/24 flex flex-col sm:flex-row items-center sm:items-start gap-y-11">
        <div class="w-16/24 sm:w-8/24 flex flex-col items-center gap-5">
          <img src="assets/img/gfx/gfx-award.png" width="96" height="96" alt="gfx_award"/>
          <div class="w-full flex flex-col items-center text-center gap-2 px-2 sm:px-1">
						<span class="text-16 leading-122 text-brand-400">
							Povoljnosti u plaćanju za domaćinstva
						</span>
            <span class="text-14 tracking-tight">
							<span class="font-700">13%</span>
							<span>
								popusta za članove
							</span>
							<span class="font-700">
								"ZLATNOG TIMA"
							</span>
						</span>
          </div>
        </div>
        <div class="w-16/24 sm:w-8/24 flex flex-col items-center gap-5">
          <img src="assets/img/gfx/gfx-time-planning.png" width="96" height="96" alt="gfx_time_planning"/>
          <div class="w-full flex flex-col items-center text-center gap-2 px-2 sm:px-1">
						<span class="text-16 leading-122 text-brand-400">
							Povoljnosti za socijalne kategorije
						</span>
            <span class="text-14 tracking-tight">
							<span>
								Mogućnost plaćanja u
							</span>
							<span class="font-700">
								više mjesečnih rata
							</span>
						</span>
          </div>
        </div>
        <div class="w-16/24 sm:w-8/24 flex flex-col items-center gap-5">
          <img src="assets/img/gfx/gfx-social-care.png" width="96" height="96" alt="gfx_social_care"/>
          <div class="w-full flex flex-col items-center text-center gap-2 px-1">
						<span class="text-16 leading-122 text-brand-400">
							Povoljnosti za socijalne kategorije
						</span>
            <span class="text-14 trasking-tight">
							<span>Za račune do 60€, </span>
							<span class="font-700">40% </span>
							<span>popusta, za račune iznad 60€, </span>
							<span class="font-700">24€ </span>
							<span>popusta</span>
						</span>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full bg-brand-100 py-6">
      <div class="container">
        <div class="w-full sm:w-12/24 grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-y-[22px]">
          <div class="sm:pr-2/24 md:pr-4/24 flex flex-col gap-2">
            <h5 class="text-16 leading-122 font-700">
              Obaveze kupca
            </h5>
            <span class="text-14 leading-140 tracking-tight">
							Kupac je dužan da račun za utrošenu električnu energiju plati u roku od 8 dana od dana dostavljanja računa.
						</span>
          </div>
          <div class="sm:pr-2/24 md:pr-4/24 flex flex-col gap-2">
            <h5 class="text-16 leading-122 font-700">
              Prigovor na račun
            </h5>
            <span class="text-14 leading-140 tracking-tight">
							Kupac ima pravo prigovora na račun u roku od 8 dana od dana dostavljanja računa.
						</span>
          </div>
          <div class="sm:pr-2/24 md:pr-4/24 flex flex-col gap-2">
            <h5 class="text-16 leading-122 font-700">
              Obaveze Snabdjevača
            </h5>
            <span class="text-14 leading-140 tracking-tight">
							Ukoliko kupac ne plati dug u utvrđenom roku, Snabdjevač će po isteku 8 dana od isteka roka za plaćanje dostaviti Upozorenje o obustavi snabdijevanja električnom energijom. Ukoliko ni po isteku 8 dana od dana dostavljanja pisanog Upozorenja kupac ne izmiri dug, Snabdjevač će obustaviti dalje snabdijevanje električnom energijom.
						</span>
          </div>
          <div class="sm:pr-2/24 md:pr-4/24 flex flex-col gap-2">
            <h5 class="text-16 leading-122 font-700">
              Kako podnijeti prigovor?
            </h5>
            <span class="text-14 leading-140 tracking-tight">
							Prigovor se podnosi u pisanoj formi na posebnom obrascu na šalterima FC Snabdijevanje.
						</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isMobileLayout" class="container mt-8">
      <app-homepage-call-center></app-homepage-call-center>
    </div>
    <div class="container sm:mb-56 md:mb-40"
         [ngClass]="{'sm:mb-56 md:mb-40': bill, 'sm:mb-0 md:mb-0': !bill}">
      <app-billing-info></app-billing-info>
    </div>
  </div>
</div>
