import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

function initializeApp(): void {
  Bugsnag.start({
    apiKey: 'a08ecc7e3028a8845cc71a8f16620c6f',
    enabledReleaseStages: ['production'],
    collectUserIp: false
  });
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeApp,
      multi: true,
    }
  ]
})
export class BugsnagModule { }
