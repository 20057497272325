export class ApiError {
  status_code: number;
  error_code?: string;
  errors?: any;
  message: string;

  public constructor(data?: {}) {
    Object.assign(this, data);
  }

}
