import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from '../../../../@shared/services/seo/seo.service';

@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements AfterViewInit {

    constructor(private seoService: SeoService,
                private activatedRoute: ActivatedRoute
    ) {
    }

    ngAfterViewInit(): void {
        this.seoService.refreshForRoute(this.activatedRoute.snapshot);
    }

}
