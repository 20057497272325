import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Card } from '../../../card/models/card.model';
import { CardService } from '../../../card/services/card.service';

@Component({
  selector: 'app-detach-card-modal',
  templateUrl: './detach-card-modal.component.html'
})
export class DetachCardModalComponent {

  @Input() card: Card;
  @Input() verification_uuid: string;

  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();
  @Output() cardDetached: EventEmitter<void> = new EventEmitter<void>();

  loading: boolean = false;

  constructor(private cardService: CardService) {
  }

  detachCard(): void {
    this.loading = true;
    this.cardService.detach(this.card.id, {
      verification_uuid: this.verification_uuid
    }).subscribe(() => {
      this.loading = false;
      this.cardDetached.emit();
    });
  }

  cancel(): void {
    this.canceled.emit();
  }

}
