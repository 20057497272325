import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_ID, Inject, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy, Scroll } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterComponent } from './@shared/components/footer/footer.component';
import { HeaderComponent } from './@shared/components/header/header.component';
import { AppLayoutComponent } from './@shared/layouts/app-layout/app-layout.component';
import { NotFoundComponent } from './@shared/layouts/not-found-layout/not-found.component';
import { SharedModule } from './@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomReuseStrategy } from './Library/custom-reuse-strategy';
import { FormComponent } from './Library/form/form.component';
import { LocaleInterceptor } from './Library/interceptors/locale.interceptor';
import { BaseComponent } from './Library/compontents/base.component';
import { filter } from 'rxjs/operators';
import { UUIDInterceptor } from './Library/interceptors/uuid.interceptor';
import { HomepageModule } from './@modules/homepage/homepage.module';
import { TermsModule } from './@modules/terms/terms.module';
import { FaqModule } from './@modules/faq/faq.module';
import { SafetyModule } from './@modules/safety/safety.module';
import { BugsnagModule } from './@modules/bugsnag/bugsnag.module';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        AppLayoutComponent,
        NotFoundComponent,
        BaseComponent,
        FormComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        HttpClientModule,
        SharedModule,
        HomepageModule,
        TermsModule,
        FaqModule,
        SafetyModule,
        BugsnagModule
    ],
    providers: [
        {
            provide: APP_ID,
            useValue: 'base'
        },
        {
            provide: RouteReuseStrategy,
            useClass: CustomReuseStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LocaleInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UUIDInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    urlBeforeRedirects: string;

    constructor(
        @Inject(LOCALE_ID) public locale: string,
        @Inject(PLATFORM_ID) private platformId,
        router: Router, viewportScroller: ViewportScroller,
        library: FaIconLibrary
    ) {

        router.events.pipe(
            filter(e => e instanceof Scroll)
        ).subscribe((e: Scroll) => {
            if (e.position) {
                // backward navigation
                setTimeout(() => {
                    viewportScroller.scrollToPosition(e.position);
                }, 1000);
            } else if (e.anchor) {
                // anchor navigation
                setTimeout(() => {
                    viewportScroller.scrollToPosition(e.position);
                }, 1000);
            } else {
                // forward navigation

                const urlAfterRedirectsIndex = e.routerEvent.urlAfterRedirects.indexOf('?');
                let urlAfterRedirects = e.routerEvent.urlAfterRedirects;
                if (urlAfterRedirectsIndex > -1) {
                    urlAfterRedirects = urlAfterRedirects.substr(0, urlAfterRedirectsIndex);
                }
                if (this.urlBeforeRedirects) {
                    const urlIndex = this.urlBeforeRedirects.indexOf('?');
                    let url = this.urlBeforeRedirects;
                    if (urlIndex > -1) {
                        url = url.substr(0, urlIndex);
                    }
                    if (urlAfterRedirects !== url) {
                        viewportScroller.scrollToPosition([0, 0]);
                    }
                } else {
                    viewportScroller.scrollToPosition([0, 0]);
                }

            }
            this.urlBeforeRedirects = e.routerEvent.urlAfterRedirects;
        });

        let token = null;
        if (isPlatformBrowser(this.platformId)) {
            token = localStorage.getItem('token');
        }

    }
}
