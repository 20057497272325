import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Verification } from '../../../verification/models/verification.model';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Account } from '../../../account/models/account.model';

@Component({
  selector: 'app-homepage-get-saved-account',
  templateUrl: './homepage-get-saved-account.component.html'
})
export class HomepageGetSavedAccountComponent {

  @Input() clientEmail: string;
  @Output() savedAccountVerified: EventEmitter<Verification> = new EventEmitter();

  verification: Verification;

  accountModalOpen: boolean = false;
  accountVerified: boolean = false;
  accountHasData: boolean = false;

  faCheck = faCheck;

  openAccountModal(): void {
    this.accountModalOpen = true;
  }

  accountModalCanceled(): void {
    this.accountModalOpen = false;
  }

  onAccountVerified(verification: Verification): void {
    this.accountModalOpen = false;

    this.accountVerified = true;
    this.accountHasData = this.isAccountEmpty(verification);

    this.verification = verification;

    this.savedAccountVerified.emit(verification);
  }

  isAccountEmpty(verification: Verification): boolean {
    const account: Account = verification.account;
    return account
        && account.first_name !== ''
        && account.last_name !== ''
        && account.address !== ''
        && account.city !== ''
        && account.country !== '';
  }
}
