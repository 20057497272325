<div class="w-full max-w-max">
	<div class="w-full bg-status-green-400 py-3">
		<div class="container flex flex-row items-center gap-[10px] text-white font-700">
			<fa-icon [icon]="faLockKeyhole"></fa-icon>
			<span class="text-12 sm:text-16 leading-122">
				Radi bezbjednosti Vašeg plaćanja, molimo Vas da provjerite sigurnosni status web lokacije
				<a [routerLink]="safetyRoute" class="underline inline-block p">Saznajte više</a>
			</span>
		</div>
	</div>
	<div class="w-full pt-2 pb-2 sm:pt-8 sm:pb-6 border-b border-gray-200">
		<div class="container">
			<a href="https://www.epcg.com" target="_blank">
				<img src="assets/img/logos/logo.png" alt="EPCG Logo" title="Elektroprivreda Crne Gore" width="102" height="46">
			</a>
		</div>
	</div>
	<div class="w-full bg-brand-100 py-[14px] mb-[30px] sm:mb-10">
		<div class="container">
			<span *ngIf="onHomepage"
				  class="text-14 leading-122 sm:leading-140 text-gray-700">
				Potvrda o plaćanju mjesečnog računa za električnu energiju će Vam stići od banke. Ova stranica je samo elektronski prikaz Vašeg računa, koji Vam stiže u prilogu emaila.
			</span>
			<button *ngIf="!onHomepage" (click)="goBack()" class="text-18 leading-122 text-gray-700">
				<fa-icon [icon]="faChevronLeft" class="mr-3"></fa-icon>
				<span>
					Nazad na račun
				</span>
			</button>
		</div>
	</div>
</div>
