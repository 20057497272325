<div class="fixed top-0 left-0 z-10 w-screen h-screen bg-black/60">
	<div class="container">
		<div class="w-full sm:w-12/24 sm:ml-6/24 pt-24 sm:pt-40">
			<div class="w-full bg-white rounded-3 px-7 md:px-22 py-6 md:py-11">

				<div *ngIf="!emailVerified">
					<app-account-modal-email
							[clientEmail]="accountEmail"
              [safeAccountVerification]="safeAccountVerification"
							(emailVerified)="onEmailVerified($event)">
					</app-account-modal-email>
				</div>

				<div *ngIf="emailVerified">
					<app-account-modal-verification-code
							[account_email]="accountEmail"
							[verification_uuid]="verificationUuid"
              [safeAccountVerification]="safeAccountVerification"
							(verified)="onVerificationVerified($event)">
					</app-account-modal-verification-code>
				</div>

				<div class="w-full text-center">
					<button type="button"
							(click)="cancel()"
							class="mt-5 sm:mt-6 mx-auto text-18 leading-122 text-brand-400">
						Odustani
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
