import {Component, Inject, LOCALE_ID, OnInit, PLATFORM_ID} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {NavigationStart, ResolveStart, Router} from '@angular/router';

import { SeoService } from './@shared/services/seo/seo.service';
import { GlobalService } from './@shared/services/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'base-website';
  isBackButtonClicked: boolean = false;

  constructor(
    private globalService: GlobalService,
    private seoService: SeoService,
    private router: Router,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private document: Document,
  ) {

    router.events.subscribe((event) => {
      if (event instanceof ResolveStart) {
        if (globalService.is404.value === true) {
          globalService.is404.next(false);
        }

        if (globalService.isRedirect.value) {
          globalService.isRedirect.next(false);
        }
      }

      if (event instanceof NavigationStart && event.navigationTrigger === 'popstate') {
        this.isBackButtonClicked = true;
      } else if (event instanceof NavigationStart) {
        this.isBackButtonClicked = false;
      }

      if (event instanceof NavigationStart) {
        if (isPlatformBrowser(this.platformId)) {
          this.document.body.classList.remove('overflow-hidden');
        }
      }
    });
  }

  ngOnInit(): void {
    this.seoService.init();
  }
}
