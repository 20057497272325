import { AfterViewInit, Component, EventEmitter, Inject, Input, Output, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { VerificationService } from '../../../../../verification/services/verification.service';
import { Verification } from '../../../../../verification/models/verification.model';

@Component({
  selector: 'app-account-modal-verification-code',
  templateUrl: './account-modal-verification-code.component.html',
  styleUrls: ['./account-modal-verification-code.component.scss']
})
export class AccountModalVerificationCodeComponent implements AfterViewInit {

  @Input() account_email: string;
  @Input() verification_uuid: string;
  @Input() safeAccountVerification: boolean = false;

  @Output() verified: EventEmitter<Verification> = new EventEmitter();

  inputs: any;

  codeError: boolean = false;
  accountAlreadyInUseError: boolean = false;
  loading: boolean = false;

  constructor(
      @Inject(PLATFORM_ID) private platformId,
      private verificationService: VerificationService) {
  }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.inputs = document.querySelectorAll('.code-field input');

    this.inputs.forEach((input, index): void => {
      input.dataset.index = index;
      input.addEventListener('keyup', this.onKeyup);
      input.addEventListener('paste', this.onPaste);
    });

    this.inputs[0].focus();
  }

  onKeyup = (e): void => {
    const input = e.target;
    const value = input.value;

    const isValidInput = value.match(/[0-9]/);
    input.value = isValidInput ? value[0] : '';

    const fieldIndex: number = parseInt(input.dataset.index, 10);
    if (fieldIndex < this.inputs.length - 1 && isValidInput) {
      input.nextElementSibling.focus();
    }

    if (e.key === 'Backspace' && fieldIndex > 0) {
      input.previousElementSibling.value = '';
      input.previousElementSibling.focus();
    }
  }

  onPaste = (e): void => {
    const data = e.clipboardData.getData('text');

    const valid: boolean = /^\d+$/.test(data);
    if (!valid) {
      return;
    }

    const value = data.split('');

    this.inputs.forEach((input, index) => {
      input.value = value[index] ? value[index] : '';
    });

    if (value.length <= 6) {
      this.inputs[value.length - 1].focus();
    }
  }

  submit(): void {
    this.loading = true;
    this.codeError = false;
    this.accountAlreadyInUseError = false;

    let code = '';

    this.inputs.forEach((input) => {
      code += input.value;
    });

    const isANumber: boolean = /^\d+$/.test(code);
    if (code.length !== 6 || !isANumber) {
      this.loading = false;
      this.codeError = true;
      return;
    }

    const params: {} = {
      uuid: this.verification_uuid,
      code,
      safe: this.safeAccountVerification ? 1 : 0,
      extended_fields: 'account.card'
    };

    this.verificationService.verify(params).subscribe((response: Verification): void => {
      this.verified.emit(response);
    }, error => {
      if (error.error_code === 'AccountAlreadyInUse') {
        this.accountAlreadyInUseError = true;
      } else {
        this.codeError = true;
      }
      this.loading = false;
    });

  }

  updateVerificationUUID(uuid: string): void {
    this.verification_uuid = uuid;
  }
}
