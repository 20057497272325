<div *ngIf="successful">
	<h1 class="text-20 sm:text-30 font-700 text-status-green-400 mb-5">
		<fa-icon [icon]="faCircleCheck"></fa-icon>
		<span>
			Transakcija je uspješna!
		</span>
	</h1>
	<div class="w-full rounded-2 bg-status-green-100 border border-status-green-400 py-[29px] sm:py-[42px] px-8 flex flex-col gap-4 sm:pr-5/24 tracking-normal">
		<div class="flex flex-col gap-1">
			<span class="text-16 text-gray-400">
				Iznos transakcije:
			</span>
			<span class="text-30 font-700">{{ formattedAmount }} €</span>
		</div>
		<div class="flex flex-col gap-1">
			<span class="text-16 text-gray-400">
				Tip kartice:
			</span>
			<span class="text-18">{{ transaction.credit_card_type }}</span>
		</div>
		<div class="flex flex-col gap-1">
			<span class="text-16 text-gray-400">
				Vrijeme transakcije:
			</span>
			<span class="text-18">{{ transaction.createdAtDate | date: 'HH:mm - MMMM dd, yy' }}</span>
		</div>
		<div class="flex flex-col gap-1">
			<span class="text-16 text-gray-400">
				Kod transakcije:
			</span>
			<span class="text-18">{{ transaction.code }}</span>
		</div>
		<div class="flex flex-col gap-1">
			<span class="text-16 text-gray-400">
				Autorizacioni kod banke:
			</span>
			<span class="text-18">{{ transaction.auth_code }}</span>
		</div>
	</div>
</div>

<div *ngIf="failed">
	<h1 class="text-20 sm:text-30 font-700 text-status-red-400 mb-5">
		<fa-icon [icon]="faCircleExclamation"></fa-icon>
		<span>
			Transakcija je neuspješna!
		</span>
	</h1>
	<div class="w-full rounded-2 bg-status-red-100 border border-status-red-400 py-[29px] sm:py-[42px] px-8 flex flex-col gap-4 sm:pr-5/24 tracking-normal">
		<p class="text-20 sm:text-24 font-700">
			<span *ngIf="!firstError">
				Došlo je do neočekivane greške.
			</span>
			<span *ngIf="firstError">
				{{ firstError.message }}
			</span>
		</p>
		<p class="text-16 sm:text-20 font-700 sm:font-400">
			Da ponovite plaćanje, kliknite na link iz mejla koji ste dobili kao Vaš mjesečni račun.
		</p>
		<div class="flex flex-col gap-1">
			<span class="text-16 text-gray-400">
				Tip kartice:
			</span>
			<span class="text-18">{{ transaction.credit_card_type }}</span>
		</div>
		<div class="flex flex-col gap-1">
			<span class="text-16 text-gray-400">
				Vrijeme transakcije:
			</span>
			<span class="text-18">{{ transaction.createdAtDate | date: 'HH:mm - MMMM dd, yy' }}</span>
		</div>
		<div class="flex flex-col gap-1">
			<span class="text-16 text-gray-400">
				Kod transakcije:
			</span>
			<span class="text-18">{{ transaction.code }}</span>
		</div>
	</div>
</div>

<div *ngIf="transaction && !successful && !failed">
	<h1 class="text-20 sm:text-30 font-700 text-status-red-400 mb-5">
		<fa-icon [icon]="faCircleExclamation"></fa-icon>
		<span>
			Transakcija je neuspješna!
		</span>
	</h1>
	<div class="w-full rounded-2 bg-status-red-100 border border-status-red-400 py-[29px] sm:py-[42px] px-8 flex flex-col gap-4 sm:pr-6/24">
		<p class="text-20 sm:text-24 font-700 tracking-normal">Došlo je do neočekivane greške.</p>
	</div>
</div>

<div *ngIf="!transaction">
	<h1>
		Molimo Vas kliknite na link iz mejla koji ste dobili, kako biste mogli plaćati Vaše račune putem online usluge EPCG.
	</h1>
</div>

