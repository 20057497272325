import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafetyComponent } from './components/safety/safety.component';
import { SharedModule } from '../../@shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { BillingInfoModule } from '../billing-info/billing-info.module';


@NgModule({
    declarations: [
        SafetyComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        FontAwesomeModule,
        RouterModule,
        BillingInfoModule
    ]
})
export class SafetyModule {
}
