import { AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy, Output, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AccountService } from '../../../../../account/services/account.service';
import { CountdownService } from '../../services/countdown.service';

@Component({
  selector: 'app-account-modal-countdown',
  templateUrl: './account-modal-countdown.component.html'
})
export class AccountModalCountdownComponent implements AfterViewInit {

  @Input() account_email: string;
  @Output() emailVerified: EventEmitter<string> = new EventEmitter<string>();

  countdownElapsed: boolean = false;
  loading: boolean = false;

  constructor(
      @Inject(PLATFORM_ID) private platformId,
      private accountService: AccountService,
      private countdownService: CountdownService) {
  }

  ngAfterViewInit(): void {
    this.countdownService.elapsedSecondsSubject.subscribe((seconds: number): void => {
      const counter: HTMLElement = document.getElementById('counter');
      if (counter) {
        counter.innerHTML = '0:' + (seconds < 10 ? '0' : '') + String(seconds);
      }
      if (seconds <= 0) {
        this.countdownElapsed = true;
      }
    });
  }

  resendCode(): void {
    this.countdownElapsed = false;

    this.loading = true;
    this.accountService.verifyAccount(this.account_email).subscribe((verification_uuid: string) => {
      this.loading = false;
      this.emailVerified.emit(verification_uuid);
      this.countdownService.countdown(verification_uuid);
    }, error => {
      this.loading = false;
    });
  }

}
